import React, { useContext, useEffect, useState } from "react";
import api from "../utils/axios";
import { Button, Input, Select, Stack, Text } from "@chakra-ui/react";
import { UserContext } from "../context/UserContext";
import config from "../config.json";

function AdminUserFilter({
  setFilteredUsers,
  setPageLoading,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageTotal,
  pageNumberChange,
  sortByRegisterTimestamp,
  onlyAttendingAndAccepted,
  refreshToggle,
}) {
  const { language } = useContext(UserContext);

  const baseFilters = {
    firstName: "",
    lastName: "",
    chineseName: "",
    email: "",
    attending: "",
    commuting: "",
    checkedIn: "",
    checkedOut: "",
    familyId: "",
    groupName: "",
    groupLanguage: "",
    registrationStatus: "",
    hasSpecialNeeds: "",
    church: "",
    age: ["", ""],
    grade: ["", ""],
    gender: "",
    role: "",
    // streetNo: "",
    // streetName: "",
    // city: "",
    // state: "",
    // zipCode: "",
  };

  const [searchFilter, setSearchFilter] = useState("");
  const [addFilterField, setAddFilterField] = useState("");
  const [addFilterQuery, setAddFilterQuery] = useState("");
  const [addFilterBoundsQuery, setAddFilterBoundsQuery] = useState(["", ""]);
  const [churchList, setChurchList] = useState([]);

  const [filters, setFilters] = useState({ ...baseFilters });

  // TODO: Change config.MDR_START_DATE to active event?, and make this global util function
  function getAge(birthdate) {
    if (birthdate == null) {
      return false;
    }

    const mdrDate = new Date(config.MDR_START_DATE);
    let calculatedAge = mdrDate.getFullYear() - birthdate.getFullYear();
    const monthDiff = mdrDate.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && mdrDate.getDate() < birthdate.getDate())
    ) {
      calculatedAge -= 1;
    }

    return calculatedAge;
  }

  function fetchUserData() {
    setPageLoading(true);
    api.get("/api/churches").then((res) => {
      setChurchList(res.data);
    });

    let getUsersUrl = "/api/users/admin-table-format";
    if (sortByRegisterTimestamp) {
      getUsersUrl = "/api/users/admin-table-format-reg-time/";
    }

    let queryFilters = { ...filters };
    if (onlyAttendingAndAccepted) {
      queryFilters["attending"] = true;
      queryFilters["registrationStatus"] = "Accepted";
    }

    api
      .get(getUsersUrl, {
        params: {
          page: pageNumber,
          limit: pageSize,
          searchQuery: searchFilter,
          ...queryFilters,
        },
      })
      .then((res) => {
        const dbUsers = res.data.users.map((dbUser) => {
          const updatedDbUser = {
            ...dbUser,
            groupName: dbUser.group != null ? dbUser.group?.name : "",
            groupLanguage: dbUser.group != null ? dbUser.group?.language : "",
            age:
              dbUser.birthdate != null
                ? getAge(new Date(dbUser.birthdate))
                : -1,
          };

          return updatedDbUser;
        });
        setFilteredUsers(dbUsers);
        setPageTotal(Math.ceil(res.data.total / pageSize));
      });
  }

  function renderFilterContent() {
    if (addFilterField === "groupLanguage") {
      return (
        <Select
          placeholder={
            language === "en" ? "Select a Language" : "(Select a Language)"
          }
          value={addFilterQuery}
          onChange={(e) => {
            setAddFilterQuery(e.target.value);
          }}
        >
          <option value="Chinese">
            {language === "en" ? "Chinese" : "(Chinese)"}
          </option>
          <option value="English">
            {language === "en" ? "English" : "(English)"}
          </option>
        </Select>
      );
    }
    if (addFilterField === "gender") {
      return (
        <Select
          placeholder={language === "en" ? "Select Gender" : "(Select Gender)"}
          value={addFilterQuery}
          onChange={(e) => {
            setAddFilterQuery(e.target.value);
          }}
        >
          <option value="Male">{language === "en" ? "Male" : "(Male)"}</option>
          <option value="Female">
            {language === "en" ? "Female" : "(Female)"}
          </option>
        </Select>
      );
    }
    if (addFilterField === "registrationStatus") {
      return (
        <Select
          placeholder={language === "en" ? "Select Status" : "(Select Status)"}
          value={addFilterQuery}
          onChange={(e) => {
            setAddFilterQuery(e.target.value);
          }}
        >
          {config.REGISTRATION_STATUSES.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      );
    }
    if (
      addFilterField === "attending" ||
      addFilterField === "commuting" ||
      addFilterField === "checkedIn" ||
      addFilterField === "checkedOut" ||
      addFilterField === "hasSpecialNeeds"
    ) {
      return (
        <Select
          placeholder={language === "en" ? "Select" : "(Select)"}
          value={addFilterQuery}
          onChange={(e) => {
            setAddFilterQuery(e.target.value);
          }}
        >
          <option value="true">{language === "en" ? "Yes" : "(Yes)"}</option>
          <option value="false">{language === "en" ? "No" : "(No)"}</option>
        </Select>
      );
    }
    if (addFilterField === "church") {
      return (
        <Select
          placeholder={language === "en" ? "Select Church" : "(Church)"}
          value={addFilterQuery}
          onChange={(e) => setAddFilterQuery(e.target.value)}
        >
          {churchList.map((item) => (
            <option value={item._id} key={item._id}>
              {`${item.name}: ${item.long_name}`}
            </option>
          ))}
        </Select>
      );
    }
    if (addFilterField === "age") {
      return (
        <>
          <Input
            placeholder="From"
            value={addFilterBoundsQuery[0]}
            onChange={(e) =>
              setAddFilterBoundsQuery([e.target.value, addFilterBoundsQuery[1]])
            }
          />
          <Input
            placeholder="To"
            value={addFilterBoundsQuery[1]}
            onChange={(e) =>
              setAddFilterBoundsQuery([addFilterBoundsQuery[0], e.target.value])
            }
          />
        </>
      );
    }
    if (addFilterField === "grade") {
      return (
        <>
          <Select
            placeholder="From"
            value={addFilterBoundsQuery[0]}
            onChange={(e) =>
              setAddFilterBoundsQuery([e.target.value, addFilterBoundsQuery[1]])
            }
          >
            {config.GRADES.map((grade) => (
              <option key={grade} value={grade}>
                {grade}
              </option>
            ))}
          </Select>
          <Select
            placeholder="To"
            value={addFilterBoundsQuery[1]}
            onChange={(e) =>
              setAddFilterBoundsQuery([addFilterBoundsQuery[0], e.target.value])
            }
          >
            {config.GRADES.map((grade) => (
              <option key={grade} value={grade}>
                {grade}
              </option>
            ))}
          </Select>
        </>
      );
    }
    return (
      <Input
        placeholder={language === "en" ? "Filter Value" : "過濾字"}
        value={addFilterQuery}
        onChange={(e) => setAddFilterQuery(e.target.value)}
      />
    );
  }

  useEffect(() => {
    fetchUserData();
    setPageLoading(false);
  }, [pageSize, filters, searchFilter, pageNumberChange, refreshToggle]);

  function handleFilterSubmit(e) {
    e.preventDefault();

    setPageNumber(1);
    if (addFilterField.length > 0) {
      if (addFilterField === "age" || addFilterField === "grade") {
        setFilters({ ...filters, [addFilterField]: addFilterBoundsQuery });
        setAddFilterBoundsQuery(["", ""]);
      } else {
        setFilters({ ...filters, [addFilterField]: addFilterQuery });
        setAddFilterQuery("");
      }
      setAddFilterField("");
    }
  }

  return (
    <>
      <Input
        placeholder={language == "en" ? "Search" : "搜尋"}
        value={searchFilter}
        onChange={(e) => {
          setSearchFilter(e.target.value);
          setPageNumber(1);
        }}
        margin={2}
        maxWidth={300}
      />
      <form onSubmit={handleFilterSubmit}>
        <Stack direction="row" spacing={1} margin={2}>
          <Select
            placeholder={
              language === "en" ? "Select Filter Field Name" : "選擇過濾字段名"
            }
            value={addFilterField}
            onChange={(e) => setAddFilterField(e.target.value)}
          >
            {Object.keys(filters).map((key) => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </Select>
          {renderFilterContent()}
          <Button type="submit" paddingX={10}>
            {language === "en" ? "Apply" : "提交"}
          </Button>
        </Stack>
      </form>
      {Object.values(filters).some(
        (val) =>
          (typeof val === "string" && val.length > 0) ||
          (Array.isArray(val) && val.some((x) => x !== ""))
      ) && (
        <Button
          margin={1}
          onClick={() => {
            setFilters({ ...baseFilters });
          }}
        >
          Clear Filters
        </Button>
      )}
      <div>
        {Object.entries(filters)
          .filter((obj) => {
            if (Array.isArray(obj[1])) {
              return obj[1][0].length > 0 || obj[1][1].length > 0;
            }
            return obj[1].length > 0;
          })
          .map(([key, value]) => {
            if (key === "age") {
              return <p key={key}>{`${key}: ${value[0]} to ${value[1]}`}</p>;
            }
            if (key === "grade") {
              return (
                <p key={key}>{`${key}: ${value[0] || config.GRADES[0]} to ${
                  value[1] || config.GRADES[config.GRADES.length - 1]
                }`}</p>
              );
            }
            if (key === "church") {
              const churchObj = churchList.find((c) => c._id === value);
              return (
                <p
                  key={key}
                >{`${key}: ${churchObj?.name}: ${churchObj?.long_name}`}</p>
              );
            }
            return <p key={key}>{`${key}: ${value}`}</p>;
          })}
      </div>
      {onlyAttendingAndAccepted && (
        <Text fontSize="xs">*Only displaying attending and accepted users</Text>
      )}
    </>
  );
}

export default AdminUserFilter;
