import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../utils/axios";
import { Box, Button, Heading, Input, Stack } from "@chakra-ui/react";

function ResetPassword() {
  const initialized = useRef(false);
  const { token } = useParams();
  const [userId, setUserId] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  async function validateToken() {
    try {
      const response = await api.post(
        `/api/auth/validate-reset-token/${token}`
      );
      if (response.status === 200) {
        setUserId(response.data.userId);
        setEmail(response.data.email);
        setIsLoading(false);
        setServerError(false);
      }
    } catch (error) {
      setIsLoading(false);
      setServerError(true);
    }
  }

  useEffect(() => {
    // This is necessary to prevent useEffect from running twice in StrictMode
    if (!initialized.current) {
      initialized.current = true;
      validateToken();
    }
  }, []);

  async function submitUpdatePassword(e) {
    e.preventDefault();
    if (emailInput !== email) {
      setFormErrorMessage(
        "Incorrect email associated with password reset link."
      );
      return;
    }
    if (password !== confirmPassword) {
      setFormErrorMessage("Passwords do not match.");
      return;
    }
    if (password.length < 6) {
      setFormErrorMessage("Passwords must be at least 6 characters long.");
      return;
    }

    setFormErrorMessage("");
    setServerError(false);
    setUpdated(false);

    try {
      const response = await api.post(`/api/auth/reset-password/${userId}`, {
        password,
        resetPasswordToken: token,
      });
      if (response.status === 200) {
        setUpdated(true);
      } else {
        setServerError(true);
      }
    } catch (err) {
      setServerError(true);
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  if (serverError) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <Heading textAlign="center" margin="20px">
          Password Reset
        </Heading>
        <Heading textAlign="center" as="h5" size="sm">
          Problem resetting password. Password reset token may be expired.
          Please send another reset link.
        </Heading>
        <Button size="sm" margin="20px">
          <Link to="/forgot-password">Forgot Password?</Link>
        </Button>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <Heading textAlign="center" margin="20px">
          Password Reset
        </Heading>
        <p>Validating Password Reset Token...</p>
      </Box>
    );
  }

  if (updated) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <Heading textAlign="center" margin="20px">
          Password Reset
        </Heading>
        <p>
          Your password has been successfully reset. Please try logging in
          again.
        </p>
      </Box>
    );
  }

  return (
    <>
      <Heading textAlign="center" margin="20px">
        Password Reset
      </Heading>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <form onSubmit={submitUpdatePassword}>
          <Stack direction="column" spacing={5}>
            <Input
              onChange={(e) => setEmailInput(e.target.value)}
              value={emailInput}
              type="text"
              placeholder="Email"
            />
            <Input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              placeholder="New Password"
            />
            <Input
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              type="password"
              placeholder="Confirm New Password"
            />
            <p>{formErrorMessage}</p>
            <Button type="submit">Update Password</Button>
          </Stack>
        </form>
      </Box>
    </>
  );
}

export default ResetPassword;
