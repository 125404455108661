import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Select,
  Spinner,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import AdminUserFilter from "../../../components/AdminUserFilter";
import AdminUserTableFooter from "../../../components/AdminUserTableFooter";
import config from "../../../config.json";
import { hasPermission } from "../../../utils/helpers";

function AdminAssignDorms() {
  const { user, language } = useContext(UserContext);

  const [filteredUsers, setFilteredUsers] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumberChange, setPageNumberChange] = useState(false);

  const [dorms, setDorms] = useState([]);
  const [dormsFiltered, setDormsFiltered] = useState([]);
  const [dormCapacities, setDormCapacities] = useState({});

  const [updateNewDorm, setUpdateNewDorm] = useState(null);
  const [updateOldDorm, setUpdateOldDorm] = useState(null);

  const [updateUserId, setUpdateUserId] = useState(null);

  useEffect(() => {
    api.get("/api/dorms").then((res) => {
      setDorms(res.data);
      if (user.roleChurchId?._id) {
        setDormsFiltered(
          res.data.filter((d) => d.church?._id === user.roleChurchId._id)
        );
      } else {
        setDormsFiltered(res.data);
      }
    });
    updateDormCapacities();
  }, []);

  async function updateDormCapacities() {
    api.get("/api/dorms/capacities").then((res) => {
      setDormCapacities(res.data);
    });
  }

  function getAge(birthdate) {
    if (birthdate == null) {
      return false;
    }

    const mdrDate = new Date(config.MDR_START_DATE);
    let calculatedAge = mdrDate.getFullYear() - birthdate.getFullYear();
    const monthDiff = mdrDate.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && mdrDate.getDate() < birthdate.getDate())
    ) {
      calculatedAge -= 1;
    }

    return calculatedAge;
  }

  function populateUserTableFields(u) {
    return {
      ...u,
      groupName: u.group != null ? u.group?.name : "",
      groupLanguage: u.group != null ? u.group?.language : "",
      age: u.birthdate != null ? getAge(new Date(u.birthdate)) : -1,
    };
  }

  function renderDormName(d) {
    if (!d) {
      return "None";
    }

    const name = d.name ? `, ${d.name}` : "";
    return `(${d.curr_occupancy}/${d.max_occupancy}) ${d.building?.name}: [Room ${d.room_number}${name}, Floor ${d.floor}]`;
  }

  async function handleUpdate(u) {
    if (!hasPermission(user, ["assign_dorms", "assign_dorms_for_church"])) {
      alert("You do not have permission to update dorms");
      return;
    }

    let userObj = u;

    userObj = {
      ...u,
      dorm: updateNewDorm || u.dorm,
    };

    if (updateNewDorm && updateNewDorm !== "None") {
      const newDorm = dorms.find((d) => d._id === updateNewDorm);
      if (newDorm.curr_occupancy >= newDorm.max_occupancy) {
        alert("Dorm is full");
        setUpdateNewDorm(updateOldDorm);
        return;
      }
    }

    if (updateNewDorm === "None") {
      delete userObj.dorm;
    }

    try {
      const updatedUser = (
        await api.put(
          `/api/users/admin-table-format/assign-dorms/${u._id}`,
          userObj
        )
      ).data;

      const updatedUsers = filteredUsers.map((oldUser) => {
        if (oldUser._id === u._id) {
          return populateUserTableFields(updatedUser);
        }
        return oldUser;
      });

      setFilteredUsers(updatedUsers);

      if (updateNewDorm != null && updateNewDorm !== updateOldDorm) {
        dorms.forEach((d) => {
          if (d._id === updatedUser.dorm?._id) {
            d.curr_occupancy += 1;
          }
          if (d._id === updateOldDorm) {
            d.curr_occupancy -= 1;
          }
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setUpdateUserId(null);
    setUpdateOldDorm(null);
    setUpdateNewDorm(null);

    updateDormCapacities();
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Assign Users to Dorms" : "将用户分配到宿舍"}
      </Heading>
      <Text as="b">
        {language === "en"
          ? `Current dorm occupancy: ${
              dormCapacities.currOccupancy != undefined
                ? dormCapacities.currOccupancy
                : ""
            }`
          : `住宿人數: ${
              dormCapacities.currOccupancy != undefined
                ? dormCapacities.currOccupancy
                : ""
            }`}
      </Text>
      <Text as="b">
        {language === "en"
          ? `Max dorm occupancy: ${
              dormCapacities.maxCapacity != undefined
                ? dormCapacities.maxCapacity
                : ""
            }`
          : `宿舍床位總數: ${
              dormCapacities.maxCapacity != undefined
                ? dormCapacities.maxCapacity
                : ""
            }`}
      </Text>
      <AdminUserFilter
        setFilteredUsers={setFilteredUsers}
        setPageLoading={setPageLoading}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageTotal={setPageTotal}
        pageNumberChange={pageNumberChange}
        onlyAttendingAndAccepted
      />
      {pageLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <TableContainer margin={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th scope="col">First Name</Th>
                  <Th scope="col">Last Name</Th>
                  <Th scope="col">Chin. Name</Th>
                  <Th scope="col">Fam. Id</Th>
                  <Th scope="col">Age</Th>
                  <Th scope="col">Grade</Th>
                  <Th scope="col">Gender</Th>
                  <Th scope="col">Commuting</Th>
                  <Th scope="col">Preferred Lang.</Th>
                  <Th scope="col">Spc. Needs</Th>
                  <Th scope="col">Reg. Status</Th>
                  <Th scope="col">Dorm</Th>
                  <Th scope="col"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers &&
                  filteredUsers.map((u) => (
                    <Tr
                      key={u._id}
                      backgroundColor={
                        u._id != updateUserId
                          ? ""
                          : updateNewDorm === updateOldDorm
                          ? ""
                          : "lightblue"
                      }
                    >
                      <Td>{u.firstName}</Td>
                      <Td>{u.lastName}</Td>
                      <Td>{u.chineseName}</Td>
                      <Td>{u.familyId}</Td>
                      <Td>{u.age >= 0 ? u.age : ""}</Td>
                      <Td>{u.grade}</Td>
                      <Td>{u.gender}</Td>
                      <Td>
                        {u.resident_commuter_status?.includes("Commuting")
                          ? "Yes"
                          : "No"}
                      </Td>
                      <Td>{u.preferredLanguage}</Td>
                      <Td>{u.hasSpecialNeeds ? "Yes" : "No"}</Td>
                      <Td>{u.registrationStatus}</Td>
                      <Td>
                        <Select
                          value={
                            u._id === updateUserId
                              ? updateNewDorm || u.dorm?._id
                              : u.dorm?._id || "None"
                          }
                          style={{
                            color:
                              u.dorm &&
                              !dormsFiltered.some(
                                (d) => d._id === u.dorm._id
                              ) &&
                              (!updateNewDorm || updateNewDorm === u.dorm._id)
                                ? "red"
                                : "black",
                          }}
                          onChange={async (e) => {
                            setUpdateNewDorm(e.target.value);
                            setUpdateOldDorm(u.dorm?._id);
                            setUpdateUserId(u._id);
                          }}
                        >
                          <option value="None" style={{ color: "black" }}>
                            None
                          </option>
                          {u.dorm &&
                            !dormsFiltered.some(
                              (d) => d._id === u.dorm._id
                            ) && (
                              <option
                                value={u.dorm?._id}
                                style={{ color: "red" }}
                              >
                                {renderDormName(
                                  dorms.find((d) => d._id === u.dorm?._id)
                                )}
                              </option>
                            )}
                          {dormsFiltered &&
                            dormsFiltered.map((d) => {
                              return (
                                <option
                                  value={d._id}
                                  key={d._id}
                                  style={{ color: "black" }}
                                >
                                  {renderDormName(d)}
                                </option>
                              );
                            })}
                        </Select>
                      </Td>
                      <Td>
                        <Button
                          colorScheme={"green"}
                          isDisabled={
                            !hasPermission(user, [
                              "assign_dorms",
                              "assign_dorms_for_church",
                            ]) || u._id != updateUserId
                              ? true
                              : updateNewDorm === updateOldDorm
                              ? true
                              : false
                          }
                          onClick={() => handleUpdate(u)}
                        >
                          Update
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <AdminUserTableFooter
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageTotal={pageTotal}
            pageNumberChange={pageNumberChange}
            setPageNumberChange={setPageNumberChange}
          />
        </>
      )}
    </Box>
  );
}

export default AdminAssignDorms;
