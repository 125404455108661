import React, { useContext, useState, useEffect, useRef } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import api from "../../utils/axios";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";
import { isUser } from "../../utils/helpers";

function Login() {
  const { setUser, language } = useContext(UserContext);
  const navigate = useNavigate();
  const pwRef = useRef(null);

  const [registrationIsOpen, setRegistrationIsOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFound, setEmailFound] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    api.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        setRegistrationIsOpen(res.data[0].isOpen);
      }
    });
  }, []);

  useEffect(() => {
    pwRef.current.focus();
  }, [emailFound]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (email.length === 0) {
      setErrorMessage("Email cannot be empty.");
      return;
    }

    if (emailFound && password.length === 0) {
      setErrorMessage("Password cannot be empty.");
      return;
    }

    try {
      const res = await api.post("/api/auth/login", {
        email,
        password,
      });

      if (res.status === 202 && !emailFound) {
        setEmailFound(true);
        setErrorMessage("");
        return;
      }

      if (isUser(res.data) && !registrationIsOpen) {
        setErrorMessage("Registration is not open.");
        return;
      }

      setUser(res.data);
      localStorage.setItem("userData", JSON.stringify(res.data));
      if (isUser(res.data)) {
        navigate("/registration");
      } else {
        navigate("/admin/registration");
      }
    } catch (err) {
      setErrorMessage(err.response?.data?.message || "Unknown error occurred");
      console.log(err);
    }
  }

  return (
    <>
      <Heading brand="brand" textAlign="center" margin="20px">
        {language === "en" ? "Login" : "登入"}
      </Heading>
      <Box display="flex" alignItems="center" justifyContent="center">
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            {errorMessage.length > 0 &&
              (errorMessage != "Email not found." ? (
                <Text
                  as="b"
                  color="red"
                  display="flex"
                  flexDirection="row"
                  margin={1}
                >
                  {errorMessage}
                </Text>
              ) : (
                <HStack spacing={1}>
                  <Text as="b" color="red">
                    Email not found, please enter the correct email or
                  </Text>
                  <Link
                    as={ReactRouterLink}
                    color="blue"
                    fontWeight="bold"
                    to="/signup"
                  >
                    sign up.
                  </Link>
                </HStack>
              ))}

            <FormControl>
              <FormLabel>
                {language === "en" ? "Email Address" : "电邮網址"}
              </FormLabel>
              <Input
                type="text"
                placeholder={language === "en" ? "Email Address" : "(电子邮件)"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                isDisabled={emailFound}
              />
            </FormControl>
            <FormControl hidden={!emailFound}>
              <FormLabel>{language === "en" ? "Password" : "密码"}</FormLabel>
              <InputGroup>
                <Input
                  ref={pwRef}
                  type={passwordShow ? "text" : "password"}
                  placeholder={language === "en" ? "Password" : "(Password)"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setPasswordShow(!passwordShow);

                      // Set timeout of 0 to ensure input type changes before setting cursor
                      setTimeout(() => {
                        if (pwRef.current) {
                          pwRef.current.focus();
                          pwRef.current.selectionStart =
                            pwRef.current.value.length;
                        }
                      }, 0);
                    }}
                  >
                    {passwordShow ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <ButtonGroup spacing="6" alignItems="center">
              <Button type="submit" colorScheme="blue">
                {language === "en" ? "Submit" : "發出"}
              </Button>
              <ReactRouterLink to="/forgot-password">
                <Button size="sm">
                  {language === "en" ? "Forgot Password" : "忘记了您的密码"}
                </Button>
              </ReactRouterLink>
            </ButtonGroup>
          </VStack>
        </form>
      </Box>
    </>
  );
}

export default Login;
