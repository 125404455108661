import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";
import { prettyPrintChurch } from "../../../../utils/PrettyPrints";

function AdminAddEditDorm() {
  const { user, language } = useContext(UserContext);
  const { dormId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [buildings, setBuildings] = useState([]);
  const [churches, setChurches] = useState([]);

  const [roomNumber, setRoomNumber] = useState("");
  const [name, setName] = useState("");
  const [floor, setFloor] = useState("");
  const [maxOccupancy, setMaxOccupancy] = useState("");
  const [description, setDescription] = useState("");
  const [building, setBuilding] = useState(null);
  const [church, setChurch] = useState(null);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/dorms/${dormId}`).then((res) => {
        setRoomNumber(res.data.room_number?.toString() || "");
        setName(res.data.name || "");
        setFloor(res.data.floor?.toString() || "");
        setMaxOccupancy(res.data.max_occupancy?.toString() || "");
        setDescription(res.data.description || "");
        setBuilding(res.data.building?._id);
        setChurch(res.data.church);
      });
    }
    api.get("/api/buildings").then((res) => {
      setBuildings(res.data);
    });
    api.get("/api/churches").then((res) => {
      setChurches(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    const dormObj = {
      room_number: parseInt(roomNumber.trim(), 10),
      name: name.trim(),
      floor: parseInt(floor.trim(), 10),
      max_occupancy: parseInt(maxOccupancy.trim(), 10) || 1,
      description: description.trim(),
      ...(building && { building }),
      ...(church && { church }),
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/dorms`, dormObj);
      } else {
        await api.put(`/api/dorms/${dormId}`, dormObj);
      }
      navigate("/admin/reference-data/dorms");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Dorm" : "Edit Dorm"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Room Number:</FormLabel>
            <Input
              placeholder={language === "en" ? "Room Number" : "(Room Number)"}
              value={roomNumber}
              onChange={(e) => setRoomNumber(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Floor:</FormLabel>
            <Input
              placeholder={language === "en" ? "Floor" : "(Floor)"}
              value={floor}
              onChange={(e) => setFloor(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Max Occupancy:</FormLabel>
            <Input
              placeholder={
                language === "en" ? "Max Occupancy" : "(Max Occupancy)"
              }
              value={maxOccupancy}
              onChange={(e) => setMaxOccupancy(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description:</FormLabel>
            <Input
              placeholder={language === "en" ? "Description" : "(Description)"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Building:</FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select Building" : "(Select Building)"
              }
              value={building ?? ""}
              onChange={(e) => setBuilding(e.target.value || null)}
            >
              {buildings.length > 0 &&
                buildings.map((b) => (
                  <option value={b._id} key={b._id}>
                    {`${b.name} (${b.building_type})`}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Church:</FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select Church" : "(Select Church)"
              }
              value={church ?? ""}
              onChange={(e) => setChurch(e.target.value || null)}
            >
              {churches.length > 0 &&
                churches.map((c) => (
                  <option value={c._id} key={c._id}>
                    {prettyPrintChurch(c)}
                  </option>
                ))}
            </Select>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/dorms"
              margin="0px 20px"
              isDisabled={!hasPermission(user, "edit_reference_data_dorms")}
            >
              Back
            </Button>
            <Button colorScheme="blue" type="submit">
              {language === "en" ? "Submit" : "(Submit)"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditDorm;
