import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminEditChurch() {
  const { user, language } = useContext(UserContext);
  const { churchId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [longName, setLongName] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [tier, setTier] = useState("");

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/churches/${churchId}`).then((res) => {
        setName(res.data.name || "");
        setLongName(res.data.long_name || "");
        setTier(res.data.tier || "");
        setStreetNo(res.data.address.streetNo?.toString() ?? "");
        setStreetName(res.data.address?.streetName || "");
        setCity(res.data.address?.city || "");
        setState(res.data.address?.state || "");
        setZipCode(res.data.address?.zipCode || "");
      });
    }
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    const churchObj = {
      name: name.trim(),
      long_name: longName.trim(),
      tier: tier,
      address: {
        streetNo: parseInt(streetNo.trim(), 10),
        streetName: streetName.trim(),
        city: city.trim(),
        state: state.trim(),
        zipCode: zipCode.trim(),
        country: "USA",
      },
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/churches`, churchObj);
      } else {
        await api.put(`/api/churches/${churchId}`, churchObj);
      }
      navigate("/admin/reference-data/churches");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Church" : "Edit Church"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Long Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Long Name" : "(Long Name)"}
              value={longName}
              onChange={(e) => setLongName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Tier:</FormLabel>
            <Input
              placeholder={language === "en" ? "Tier" : "(Tier)"}
              value={tier}
              onChange={(e) => setTier(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Street Number:</FormLabel>
            <Input
              placeholder={
                language === "en" ? "Street Number" : "(Street Number)"
              }
              value={streetNo}
              onChange={(e) => setStreetNo(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Street Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Street Name" : "(Street Name)"}
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>City:</FormLabel>
            <Input
              placeholder={language === "en" ? "City" : "(City)"}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "State" : "(State)"} </FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select State" : "(Select State)"
              }
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="N/A">N/A</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Zip Code:</FormLabel>
            <Input
              placeholder={language === "en" ? "Zip Code" : "(Zip Code)"}
              value={zipCode}
              maxLength={5}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/churches"
              margin="0px 20px"
            >
              Back
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!hasPermission(user, "edit_reference_data_churches")}
            >
              {language === "en" ? "Submit" : "(Submit)"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminEditChurch;
