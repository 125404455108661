import React, { useContext } from "react";
import { Link as ReachLink } from "react-router-dom";
import { Box, Button, Heading } from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";

function AdminReferenceData() {
  const { language } = useContext(UserContext);

  return (
    <>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Box textAlign="center" margin="40px 0px 0px 0px">
        <Button as={ReachLink} to="dorms" margin="0px 10px">
          {language === "en" ? "Dorms" : "宿舍"}
        </Button>
        <Button as={ReachLink} to="groups" margin="0px 10px">
          {language === "en" ? "Groups" : "群組"}
        </Button>
        <Button as={ReachLink} to="classrooms" margin="0px 10px">
          {language === "en" ? "Classrooms" : "教室"}
        </Button>
        <Button as={ReachLink} to="buildings" margin="0px 10px">
          {language === "en" ? "Buildings" : "建築樓"}
        </Button>
        <Button as={ReachLink} to="churches" margin="0px 10px">
          {language === "en" ? "Churches" : "教會"}
        </Button>
        <Button as={ReachLink} to="events" margin="0px 10px">
          {language === "en" ? "Events" : "特會"}
        </Button>
        <Button as={ReachLink} to="meals" margin="0px 10px">
          {language === "en" ? "Meals" : "餐食"}
        </Button>
        <Button as={ReachLink} to="lodging" margin="0px 10px">
          {language === "en" ? "Lodging" : "住宿"}
        </Button>
        <Button as={ReachLink} to="roles" margin="0px 10px">
          {language === "en" ? "Roles" : "角色"}
        </Button>
        <Button as={ReachLink} to="options" margin="0px 10px">
          {language === "en" ? "Options" : "参加方式"}
        </Button>
      </Box>
    </>
  );
}

export default AdminReferenceData;
