import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";
import { prettyPrintBuilding } from "../../../../utils/PrettyPrints";

function AdminManageClassrooms() {
  const { user, language } = useContext(UserContext);

  const [classrooms, setClassrooms] = useState([]);
  const [buildings, setBuildings] = useState([]);

  function fetchReferenceData() {
    api.get("/api/classrooms").then((res) => {
      setClassrooms(res.data);
    });
    api.get("/api/buildings").then((res) => {
      setBuildings(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  function getClassroomString(cObj) {
    return `Room ${cObj.classroom_number}, Floor ${cObj.floor}, Building ${cObj.building?.name}`;
  }

  async function handleDelete(c) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(`Are you sure you wish to delete ${getClassroomString(c)}`)
    ) {
      try {
        await api.delete(`/api/classrooms/${c._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Classroom deleted");
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Classrooms
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Classroom Number</Th>
              <Th>Name</Th>
              <Th>Floor</Th>
              <Th>Description</Th>
              <Th>Building</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {classrooms &&
              classrooms.map((classroom) => (
                <Tr key={classroom._id}>
                  <Td>{classroom.classroom_number}</Td>
                  <Td>{classroom.name}</Td>
                  <Td>{classroom.floor}</Td>
                  <Td>{classroom.description}</Td>
                  <Td>
                    {prettyPrintBuilding(classroom.building?._id, buildings)}
                  </Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_classrooms")
                          ? `/admin/reference-data/classrooms/${classroom._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_classrooms")
                      }
                    >
                      {language === "en" ? "Update" : "[Update]"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_classrooms")
                      }
                      colorScheme="red"
                      onClick={() => handleDelete(classroom)}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          Back
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_classrooms")
              ? "/admin/reference-data/classrooms/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_classrooms")}
        >
          {language === "en" ? "Add Classroom" : "[Add Classroom]"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageClassrooms;
