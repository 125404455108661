import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import config from "../../../../config.json";
import { hasPermission } from "../../../../utils/helpers";

function AdminAddEditRole() {
  const { user, language } = useContext(UserContext);
  const { roleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [rolePermissions, setRolePermissions] = useState([]);
  const [errorName, setErrorName] = useState(false);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/roles/${roleId}`).then((res) => {
        setRoleName(res.data.name || "");
        setRoleDescription(res.data.description || "");
        setRolePermissions(res.data.permissions || []);
      });
    }
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    if (!hasPermission(user, "edit_reference_data_roles")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to edit roles.");
      return;
    }

    setErrorName(roleName.length === 0);

    if (roleName.length === 0) {
      return;
    }

    const roleObj = {
      name: roleName,
      description: roleDescription,
      permissions: rolePermissions,
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/roles`, roleObj);
      } else {
        await api.put(`/api/roles/${roleId}`, roleObj);
      }
      navigate("/admin/reference-data/roles");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Role" : "Edit Role"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl isInvalid={errorName}>
            <FormLabel>Role Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
            <FormErrorMessage>Name is required.</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Role Description:</FormLabel>
            <Input
              placeholder={language === "en" ? "Description" : "(Description)"}
              value={roleDescription}
              onChange={(e) => setRoleDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Permissions:</FormLabel>
            <CheckboxGroup
              colorScheme="green"
              value={rolePermissions}
              onChange={(e) => {
                setRolePermissions(e);
              }}
            >
              <VStack spacing={1} alignItems={"start"}>
                {config.PERMISSIONS.map((permission) => (
                  <Checkbox key={permission} value={permission}>
                    {permission.includes("for_church") ? (
                      <Tooltip label="Requires roleChurchId field to be set to restrict user to specific church">{`${permission}*`}</Tooltip>
                    ) : (
                      permission
                    )}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/roles"
              margin="0px 20px"
            >
              Back
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!hasPermission(user, "edit_reference_data_roles")}
            >
              {language === "en" ? "Submit" : "(Submit)"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditRole;
