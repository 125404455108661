import React, { useContext, useEffect, useState, useRef } from "react";
import api from "../../utils/axios";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  TableContainer,
  Textarea,
  VStack,
  FormErrorMessage,
  HStack,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import config from "../../config.json";
import { hasPermission, isAdmin, isUser, range } from "../../utils/helpers";

function RegistrationForm() {
  const { user, setUser, language } = useContext(UserContext);
  const navigate = useNavigate();
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const emailRef = useRef(null);
  const { id, familyId } = useParams();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registrationStatus, setRegistrationStatus] =
    useState("To Be Confirmed");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const [cellPhoneNumber, setCellPhoneNumber] = useState("");
  const [parentGuardianEmail, setParentGuardianEmail] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [gender, setGender] = useState("");
  const [familyRelationship, setFamilyRelationship] = useState("");
  // const [birthdate, setBirthdate] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [volunteerChildren, setVolunteerChildren] = useState("");
  const [volunteerChildrenGroups, setVolunteerChildrenGroups] = useState([]);

  const [grade, setGrade] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [church, setChurch] = useState("");
  const [churchOther, setChurchOther] = useState("");
  const [hasSpecialNeeds, setHasSpecialNeeds] = useState(false);
  const [specialNeeds, setSpecialNeeds] = useState("");
  const [registerer, setRegisterer] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [roleChurchId, setRoleChurchId] = useState(null);
  const [group, setGroup] = useState(null);
  const [leader, setLeader] = useState(false);
  const [dorm, setDorm] = useState(null);
  const [airportPickupInfo, setAirportPickupInfo] = useState(null);
  const [airportDropoffInfo, setAirportDropoffInfo] = useState(null);

  const [meals, setMeals] = useState([]);
  const [lodging, setLodging] = useState([]);

  const [roleList, setRoleList] = useState([]);
  const [churchList, setChurchList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [dormList, setDormList] = useState([]);
  const [mealList, setMealList] = useState([]);
  const [mealMap, setMealMap] = useState({});
  const [mealOrder, setMealOrder] = useState([]);
  const [lodgingList, setLodgingList] = useState([]);

  const [lodgingMealsDropdownVal, setLodgingMealsDropdownVal] = useState("");
  const [lodgingEdit, setLodgingEdit] = useState(false);
  const [mealsEdit, setMealsEdit] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [hasEditPermissions, setHasEditPermissions] = useState(true);
  const [optionsList, setOptionsList] = useState([]);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const childrenList = ["0-2 yrs old", "3-5 yrs old"];

  useEffect(() => {
    api.get("/api/roles").then((res) => {
      setRoleList(res.data);
      setUserRole(res.data.find((role) => role.permissions.length === 0)._id);
    });
    api.get("/api/churches").then((res) => {
      res.data.sort(function (a, b) {
        a = a.long_name
          ? a.long_name?.toLowerCase()
          : a.name?.toLowerCase() || "";
        b = b.long_name
          ? b.long_name?.toLowerCase()
          : b.name?.toLowerCase() || "";
        return a.localeCompare(b);
      });
      setChurchList(res.data);
    });
    api.get("/api/groups").then((res) => {
      res.data.sort(function (a, b) {
        return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
      });
      setGroupList(res.data);
    });
    api.get("/api/dorms").then((res) => {
      res.data.sort(function (a, b) {
        const buildingA = a.building?.name?.toLowerCase() || "";
        const buildingB = b.building?.name?.toLowerCase() || "";

        if (buildingA !== buildingB) {
          return buildingA.localeCompare(buildingB);
        }

        if (!a.room_number || !b.room_number) {
          return 0;
        }

        return a.room_number - b.room_number;
      });
      setDormList(res.data);
    });
    api.get("/api/meals").then((res) => {
      let mealMapTemp = {};
      let mealOrderTemp = [];

      res.data.forEach((meal) => {
        if (mealMapTemp[meal.date] == null) {
          // Add map for key value pair (name, id)
          mealMapTemp[meal.date] = {};
        }
        mealMapTemp[meal.date][meal.name] = meal._id;
        if (!mealOrderTemp.includes(meal.name)) {
          mealOrderTemp.push(meal.name);
        }
      });

      const order = ["breakfast", "lunch", "dinner"];
      mealOrderTemp.sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        const indexA = order.indexOf(a);
        const indexB = order.indexOf(b);

        // If both items are in the order array, sort them based on their index
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        // If only one item is in the order array, it comes first
        if (indexA !== -1) {
          return -1;
        }

        if (indexB !== -1) {
          return 1;
        }

        // If both items are not in the order array, maintain their relative order
        return 0;
      });

      setMealList(res.data);
      setMealMap(mealMapTemp);
      setMealOrder(mealOrderTemp);
      if (id == null || lodgingMealsDropdownVal === "Full Time") {
        setMeals([...res.data.map((m) => m._id)]);
      }
    });
    api.get("/api/lodgings").then((res) => {
      setLodgingList(res.data);
      if (id == null || lodgingMealsDropdownVal === "Full Time") {
        setLodging([...res.data.map((l) => l._id)]);
      }
    });
    api.get("/api/options").then((res) => {
      setOptionsList(res.data.filter((item) => item.enabled));
    });

    if (id != null) {
      // Updating user
      api.get(`/api/users/${id}`).then((res) => {
        const populateUser = res.data;
        setFirstName(populateUser.firstName ?? "");
        setLastName(populateUser.lastName ?? "");
        setChineseName(populateUser.chineseName ?? "");
        setEmail(populateUser.email ?? "");
        setParentGuardianEmail(populateUser.parentGuardianEmail ?? "");
        setVolunteerChildren(
          populateUser.volunteerChildrenGroups?.length === 0 ? "" : "True"
        );
        setVolunteerChildrenGroups(populateUser.volunteerChildrenGroups ?? []);
        setRegistrationStatus(populateUser.registrationStatus);
        setHomePhoneNumber(populateUser.homePhoneNumber ?? "");
        setCellPhoneNumber(populateUser.cellPhoneNumber ?? "");
        setGender(populateUser.gender ?? "");
        setFamilyRelationship(populateUser.familyRelationship ?? "");
        setBirthMonth(
          populateUser.birthdate ? populateUser.birthdate.slice(5, 7) : ""
        );
        setBirthYear(
          populateUser.birthdate ? populateUser.birthdate.slice(0, 4) : ""
        );
        setEmergencyPhoneNumber(populateUser.emergencyPhoneNumber ?? "");
        setPreferredLanguage(populateUser.preferredLanguage ?? "");
        setGrade(populateUser.grade ?? "");
        setStreetNo(populateUser.address?.streetNo?.toString() ?? "");
        setStreetName(populateUser.address?.streetName ?? "");
        setCity(populateUser.address?.city ?? "");
        setState(populateUser.address?.state ?? "N/A");
        setZipCode(populateUser.address?.zipCode ?? "");
        setChurch(
          populateUser.church?._id ?? (populateUser.churchOther ? "Other" : "")
        );
        setChurchOther(populateUser.churchOther ?? "");
        setHasSpecialNeeds(populateUser.hasSpecialNeeds || false);
        setSpecialNeeds(populateUser.specialNeeds ?? "");
        setRegisterer(populateUser.registerer || false);
        setUserRole(populateUser.role?._id ?? null);
        setRoleChurchId(populateUser.roleChurchId ?? null);
        setGroup(populateUser.group?._id ?? null);
        setLeader(populateUser.leader ?? false);
        setDorm(populateUser.dorm?._id ?? null);
        setMeals(
          populateUser.meals ? [...populateUser.meals.map((m) => m._id)] : []
        );
        setLodging(
          populateUser.lodging
            ? [...populateUser.lodging.map((m) => m._id)]
            : []
        );
        setAirportPickupInfo(populateUser.airportPickupInfo ?? null);
        setAirportDropoffInfo(populateUser.airportDropoffInfo ?? null);

        setLodgingMealsDropdownVal(populateUser.resident_commuter_status);
        if (populateUser.resident_commuter_status === "Full Time") {
          setLodgingEdit(false);
          setMealsEdit(false);
          if (lodgingList.length > 0 && mealList.length > 0) {
            setLodging([...lodgingList.map((l) => l._id)]);
            setMeals([...mealList.map((m) => m._id)]);
          }
        } else if (populateUser.resident_commuter_status === "Part Time") {
          setLodgingEdit(true);
          setMealsEdit(true);
        } else if (
          populateUser.resident_commuter_status === "Commuting - All Meals"
        ) {
          setLodgingEdit(false);
          setMealsEdit(false);
        } else if (
          populateUser.resident_commuter_status ===
          "Commuting - Pay Meals at Counter"
        ) {
          setLodgingEdit(false);
          setMealsEdit(true);
        } else {
          setLodgingMealsDropdownVal("Full Time");
          setLodgingEdit(false);
          setMealsEdit(false);
          if (lodgingList.length > 0 && mealList.length > 0) {
            setLodging([...lodgingList.map((l) => l._id)]);
            setMeals([...mealList.map((m) => m._id)]);
          }
        }

        if (
          !hasPermission(user, "edit_users") &&
          populateUser.familyId != null &&
          user?.familyId !== populateUser.familyId
        ) {
          setHasEditPermissions(false);
        }

        if (isUser(user) && !user?.registerer) {
          setHasEditPermissions(false);
        }
      });
    } else {
      // Creating new user

      setLodgingMealsDropdownVal("Full Time");
      setLodgingEdit(false);
      setMealsEdit(false);
      if (lodgingList.length > 0 && mealList.length > 0) {
        setLodging([...lodgingList.map((l) => l._id)]);
        setMeals([...mealList.map((m) => m._id)]);
      }

      if (
        isUser(user) ||
        (hasPermission(user, "edit_users") &&
          familyId === user.familyId?.toString())
      ) {
        setStreetNo(user.address?.streetNo?.toString() ?? "");
        setStreetName(user.address?.streetName ?? "");
        setCity(user.address?.city ?? "");
        setState(user.address?.state ?? "N/A");
        setZipCode(user.address?.zipCode ?? "");
        setChurch(user.church?._id ?? "");
      }
    }

    if (user == null) {
      setRegisterer(true);
    }
  }, []);

  function handleErrors(err) {
    if (err.response?.data?.message) {
      if (err.response.data.message.toLowerCase().includes("email")) {
        setEmailError(err.response.data.message);
        emailRef.current.scrollIntoView({ behavior: "auto", block: "center" });
      } else {
        setErrorMessage(err.response.data.message);
        window.scrollTo(0, 0);
      }
    } else {
      setErrorMessage("Internal Server Error");
      window.scrollTo(0, 0);
    }
    console.log(err);
  }

  async function submitNewUser(baseUserObj) {
    if (email.length === 0 || password.length === 0) {
      setErrorMessage("Email and password are required for new users.");
      window.scrollTo(0, 0);
      return;
    }

    const userObj = {
      ...baseUserObj,
      ...(password.length > 0 && { password: password.trim() }),
    };

    try {
      const newUser = (await api.post("/api/auth/register", userObj)).data;

      setUser(newUser);
      navigate("/registration");
    } catch (err) {
      handleErrors(err);
    }
  }

  async function submitUpdateUser(baseUserObj) {
    const userObj = {
      ...baseUserObj,
      ...(group && { group }),
      ...(dorm && { dorm }),
      ...(roleChurchId && { roleChurchId }),
    };

    try {
      const updatedUser = (await api.put(`/api/users/${id}`, userObj)).data;

      if (id === user._id) {
        setUser(updatedUser);
      }

      if (isUser(user)) {
        navigate("/registration");
      } else {
        navigate(-1);
      }
    } catch (err) {
      handleErrors(err);
    }
  }

  async function submitNewFamilyMember(baseUserObj) {
    const userObj = {
      ...baseUserObj,
      ...(password.length > 0 && { password: password.trim() }),
      familyId: familyId || user.familyId,
      ...(group && { group }),
      ...(dorm && { dorm }),
      ...(roleChurchId && { roleChurchId }),
    };

    try {
      await api.post("/api/auth/register", userObj);

      if (isUser(user)) {
        navigate("/registration");
      } else {
        navigate(`/admin/registration/family/${familyId}`);
      }
    } catch (err) {
      handleErrors(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    setEmailError("");
    setConfirmPasswordError("");

    if (registerer && email.length === 0) {
      setErrorMessage("Email is required if registerer is set to true.");
      window.scrollTo(0, 0);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      confirmPasswordRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
      return;
    }

    let birthdate = null;
    if (birthMonth && birthYear) {
      birthdate = new Date(parseInt(birthYear), parseInt(birthMonth) - 1);
    }

    const baseUserObj = {
      role: userRole,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      chineseName: chineseName.trimEnd(),
      email: email.length > 0 ? email.trim() : null, // null prevents duplicate key error for ""
      parentGuardianEmail: parentGuardianEmail.trim(),
      registrationStatus,
      homePhoneNumber: homePhoneNumber.trim(),
      cellPhoneNumber: cellPhoneNumber.trim(),
      emergencyPhoneNumber: emergencyPhoneNumber.trim(),
      volunteerChildrenGroups,
      gender,
      birthdate,
      preferredLanguage,
      grade,
      familyRelationship,
      address: {
        streetNo: parseInt(streetNo.trim(), 10),
        streetName: streetName.trim(),
        city: city.trim(),
        state,
        zipCode: zipCode.trim(),
        country: "USA",
      },
      registerer,
      hasSpecialNeeds,
      airportPickupInfo,
      airportDropoffInfo,
      specialNeeds: hasSpecialNeeds ? specialNeeds.trim() : "",
      leader,
      resident_commuter_status: lodgingMealsDropdownVal,
      lodging,
      meals,
      ...(church && church !== "Other" && { church: church.trim() }),
      churchOther: church === "Other" ? churchOther.trim() : null,
    };

    if (id == null) {
      if (!user) {
        submitNewUser(baseUserObj);
      } else {
        submitNewFamilyMember(baseUserObj);
      }
    } else {
      submitUpdateUser(baseUserObj);
    }
  }

  function formatPhoneNumber(input) {
    let val = input.replace(/\D/g, "");
    const size = val.length;
    if (size > 0) {
      val = `(${val}`;
    }
    if (size > 3) {
      val = `${val.slice(0, 4)}) ${val.slice(4, 11)}`;
    }
    if (size > 6) {
      val = `${val.slice(0, 9)} - ${val.slice(9)}`;
    }

    return val;
  }

  function isUnderAge(age) {
    if (!birthMonth || !birthYear) {
      return false;
    }
    let birthdate = new Date(parseInt(birthYear), parseInt(birthMonth) - 1);

    const mdrDate = new Date(config.MDR_START_DATE); // TODO: Remove hardcoding and get from event
    let calculatedAge = mdrDate.getFullYear() - birthdate.getFullYear();
    const monthDiff = mdrDate.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && mdrDate.getDate() < birthdate.getDate())
    ) {
      calculatedAge -= 1;
    }

    return calculatedAge < age;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" margin={10}>
      <form style={{ maxWidth: "100%" }} onSubmit={handleSubmit}>
        <VStack spacing={4} alignItems="flex-start">
          {errorMessage.length > 0 && (
            <Text alignSelf="center" as="b" color="red">
              {errorMessage}
            </Text>
          )}
          <FormControl isRequired>
            <FormLabel>{language === "en" ? "First Name" : "名字"}:</FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "First Name" : "名字"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              isDisabled={!hasEditPermissions}
              isRequired
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>{language === "en" ? "Last Name" : "姓氏"}:</FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "Last Name" : "姓氏"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              isDisabled={!hasEditPermissions}
              isRequired
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en" ? "Chinese Name" : "中文姓名"}:
            </FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "Chinese Name" : "中文姓名"}
              value={chineseName}
              onChange={(e) => setChineseName(e.target.value)}
              isDisabled={!hasEditPermissions}
              onInput={(e) => e.target.setCustomValidity("")}
            />
          </FormControl>
          <FormControl
            isRequired={user == null || registerer}
            isInvalid={emailError.length > 0}
          >
            <FormLabel>{language === "en" ? "Email" : "电子邮件"}:</FormLabel>
            <Input
              ref={emailRef}
              type="email"
              placeholder={language === "en" ? "Email" : "电子邮件"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isDisabled={!hasEditPermissions}
              isRequired={user == null || registerer}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  language === "en" ? "Invalid Format" : "格式不附"
                )
              }
              onInput={(e) => e.target.setCustomValidity("")}
            />
            <FormErrorMessage>{emailError}</FormErrorMessage>
          </FormControl>
          {user?.role?.permissions?.includes("change_user_passwords") &&
            id != null && (
              <Button
                display={{ base: "none", md: "inline-flex" }}
                fontSize="md"
                fontWeight={600}
                color="white"
                bg="gray.600"
                href="#"
                _hover={{
                  bg: "gray.500",
                }}
                onClick={() => navigate(`/admin/change-password/${id}`)}
              >
                {language === "en"
                  ? "Change password for user"
                  : "更改使用者密码"}
              </Button>
            )}

          {user === null && (
            <FormControl isRequired={user == null || registerer}>
              <FormLabel>{language === "en" ? "Password" : "密码"}:</FormLabel>
              <InputGroup>
                <Input
                  ref={passwordRef}
                  type={passwordShow ? "text" : "password"}
                  placeholder={language === "en" ? "Password" : "密码"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isDisabled={user != null}
                  isRequired={user == null || registerer}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      language === "en" ? "Required field" : "須填入資料"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setPasswordShow(!passwordShow);
                      // Set timeout of 0 to ensure input type changes before setting cursor
                      setTimeout(() => {
                        if (passwordRef.current) {
                          passwordRef.current.focus();
                          passwordRef.current.selectionStart =
                            passwordRef.current.value.length;
                        }
                      }, 0);
                    }}
                  >
                    {passwordShow
                      ? language === "en"
                        ? "Hide"
                        : "隱藏"
                      : language === "en"
                      ? "Show"
                      : "顯示"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
          {user === null && (
            <FormControl
              isRequired={user == null || registerer}
              isInvalid={confirmPasswordError.length > 0}
            >
              <FormLabel>
                {language === "en" ? "Confirm Password" : "密码確認"}:
              </FormLabel>
              <InputGroup>
                <Input
                  ref={confirmPasswordRef}
                  type={confirmPasswordShow ? "text" : "password"}
                  placeholder={language === "en" ? "Password" : "密码"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isDisabled={user != null}
                  isRequired={user == null || registerer}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      language === "en" ? "Required field" : "須填入資料"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      setConfirmPasswordShow(!confirmPasswordShow);

                      // Set timeout of 0 to ensure input type changes before setting cursor
                      setTimeout(() => {
                        if (confirmPasswordRef.current) {
                          confirmPasswordRef.current.focus();
                          confirmPasswordRef.current.selectionStart =
                            confirmPasswordRef.current.value.length;
                        }
                      }, 0);
                    }}
                  >
                    {confirmPasswordShow
                      ? language === "en"
                        ? "Hide"
                        : "隱藏"
                      : language === "en"
                      ? "Show"
                      : "顯示"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
            </FormControl>
          )}
          {isAdmin(user) && (
            <FormControl>
              <FormLabel>{language === "en" ? "Attending" : "參加"}:</FormLabel>
              <Checkbox
                isChecked={registrationStatus !== "Not Attending"}
                colorScheme="green"
                isDisabled={!hasEditPermissions}
                onChange={(e) => {
                  if (e.target.checked) {
                    if (
                      // eslint-disable-next-line no-alert
                      window.confirm(
                        "Are you sure you wish to set attending to true? Registration status will be set to 'To Be Confirmed'."
                      )
                    ) {
                      setRegistrationStatus("To Be Confirmed");
                    }
                  } else if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                      "Are you sure you wish to set attending to false? Group and dorm will be cleared and registration status will be set to 'Not Attending'."
                    )
                  ) {
                    setRegistrationStatus("Not Attending");
                    setGroup(null);
                    setDorm(null);
                  }
                }}
              />
            </FormControl>
          )}
          <Text>
            {(user == null || registerer) &&
              (cellPhoneNumber == null || cellPhoneNumber.length <= 0) &&
              (homePhoneNumber == null || homePhoneNumber.length <= 0) && (
                <>
                  {language === "en"
                    ? "Please fill out at least one phone number."
                    : "[]"}
                </>
              )}
          </Text>
          <FormControl
            isRequired={
              (user == null || registerer) &&
              (cellPhoneNumber == null || cellPhoneNumber.length <= 0)
            }
          >
            <FormLabel>
              {language === "en" ? "Home Phone Number" : "家里 电话号码"}:
            </FormLabel>
            <Input
              type="text"
              placeholder={
                language === "en" ? "Home Phone Number" : "家里 电话号码"
              }
              value={homePhoneNumber}
              isDisabled={!hasEditPermissions}
              onChange={(e) =>
                setHomePhoneNumber(formatPhoneNumber(e.target.value))
              }
            />
          </FormControl>
          <FormControl
            isRequired={
              (user == null || registerer) &&
              (homePhoneNumber == null || homePhoneNumber.length <= 0)
            }
          >
            <FormLabel>
              {language === "en" ? "Cell Phone Number" : "手机 电话号码"}:
            </FormLabel>
            <Input
              type="text"
              placeholder={
                language === "en" ? "Cell Phone Number" : "手机 电话号码"
              }
              value={cellPhoneNumber}
              isDisabled={!hasEditPermissions}
              onChange={(e) =>
                setCellPhoneNumber(formatPhoneNumber(e.target.value))
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>{language === "en" ? "Gender" : "性别"}:</FormLabel>
            <RadioGroup value={gender} onChange={setGender}>
              <Stack direction="row">
                <Radio value="Male" isDisabled={!hasEditPermissions}>
                  {language === "en" ? "Male" : "男"}
                </Radio>
                <Radio value="Female" isDisabled={!hasEditPermissions}>
                  {language === "en" ? "Female" : "女"}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              {language === "en" ? "Preferred Language" : "語言"}:
            </FormLabel>
            <RadioGroup
              onChange={setPreferredLanguage}
              value={preferredLanguage}
              isDisabled={!hasEditPermissions}
            >
              <Stack direction="row">
                <Radio value="English" isDisabled={!hasEditPermissions}>
                  {language === "en" ? "English" : "英文"}
                </Radio>
                <Radio value="Chinese" isDisabled={!hasEditPermissions}>
                  {language === "en" ? "Chinese" : "中文"}
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "Birthdate" : "生日"}:</FormLabel>
            <HStack
              spacing={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Select
                id="select-month"
                placeholder={
                  language === "en" ? "Select Month" : "[Select Month]"
                }
                value={birthMonth}
                isDisabled={!hasEditPermissions}
                onChange={(e) => setBirthMonth(e.target.value)}
              >
                {config.MONTHS_MAP.map(({ val, month }) => (
                  <option key={val} value={val}>
                    {month}
                  </option>
                ))}
              </Select>
              <Select
                id="select-year"
                placeholder={
                  language === "en" ? "Select Year" : "[Select Year]"
                }
                value={birthYear}
                isDisabled={!hasEditPermissions}
                onChange={(e) => setBirthYear(e.target.value)}
              >
                {range(1900, 2024).map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </Select>
            </HStack>
          </FormControl>
          {isUnderAge(18) && (
            <>
              <FormControl isRequired>
                <FormLabel>
                  {language === "en"
                    ? "Parent/Guardian Email:"
                    : "父母或監護人 电子邮件:"}
                </FormLabel>
                <Input
                  type="email"
                  placeholder={
                    language === "en"
                      ? "Parent/Guardian Email"
                      : "父母或監護人 电子邮件:"
                  }
                  value={parentGuardianEmail}
                  onChange={(e) => setParentGuardianEmail(e.target.value)}
                  isDisabled={!hasEditPermissions}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      language === "en"
                        ? "Incorrect email format"
                        : "電邮形式錯誤"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  {language === "en"
                    ? "Emergency Contact Phone Number:"
                    : "緊急狀况時聯絡電話"}
                </FormLabel>
                <Input
                  type="text"
                  placeholder={
                    language === "en"
                      ? "Emergency Contact Phone Number"
                      : "緊急狀况時聯絡電話"
                  }
                  value={emergencyPhoneNumber}
                  isDisabled={!hasEditPermissions}
                  onChange={(e) =>
                    setEmergencyPhoneNumber(formatPhoneNumber(e.target.value))
                  }
                />
              </FormControl>
            </>
          )}
          {!isUnderAge(18) && (
            <>
              <Text>
                {language === "en"
                  ? "Would you like to volunteer to help with the Children's Ministry?"
                  : "您願意幫助服事孩童班嗎？"}
              </Text>
              <FormControl>
                <RadioGroup
                  onChange={(e) => {
                    if (e === "False") {
                      setVolunteerChildrenGroups([]);
                    }
                    setVolunteerChildren(e);
                  }}
                  value={volunteerChildren}
                >
                  <Stack direction="row">
                    <Radio value="True" isDisabled={!hasEditPermissions}>
                      {language === "en" ? "Yes" : "是"}
                    </Radio>
                    <Radio value="False" isDisabled={!hasEditPermissions}>
                      {language === "en" ? "No" : "否"}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </>
          )}
          {volunteerChildren === "True" && !isUnderAge(18) && (
            <>
              <Text>
                {language === "en"
                  ? "Please select which groups you would like to volunteer for:"
                  : "請選擇願意服事的班级:"}{" "}
              </Text>
              <FormControl>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        {childrenList.map((item) => (
                          <Th key={item}>{item}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        {childrenList.map((item) => (
                          <Td key={item}>
                            <Checkbox
                              id={item}
                              isChecked={volunteerChildrenGroups.includes(item)}
                              isDisabled={!hasEditPermissions}
                              onChange={(e) => {
                                if (
                                  e.target.checked &&
                                  !volunteerChildrenGroups.includes(item)
                                ) {
                                  setVolunteerChildrenGroups([
                                    ...volunteerChildrenGroups,
                                    item,
                                  ]);
                                } else {
                                  setVolunteerChildrenGroups(
                                    volunteerChildrenGroups.filter(
                                      (l) => l !== item
                                    )
                                  );
                                }
                              }}
                            />
                          </Td>
                        ))}
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </FormControl>
            </>
          )}
          <FormControl isRequired>
            <FormLabel>
              {language === "en" ? "Grade or Occupation" : "年級或身份"}:
            </FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select Grade or Occupation" : "年級或身份"
              }
              value={grade}
              isDisabled={!hasEditPermissions}
              isRequired
              onChange={(e) => setGrade(e.target.value)}
            >
              {config.GRADES.map((g) => (
                <option key={g} value={g}>
                  {g}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en"
                ? "Family Relationship"
                : "[Family Relationship]"}
              :
            </FormLabel>
            <Select
              placeholder={
                language === "en"
                  ? "Select Family Relationship"
                  : "[Select Family Relationship]"
              }
              value={familyRelationship}
              isDisabled={!hasEditPermissions}
              onChange={(e) => setFamilyRelationship(e.target.value)}
            >
              <option value="Parent/Spouse">Parent/Spouse</option>
              <option value="Child">Child</option>
              <option value="Church Member">Church Member</option>
              <option value="Other">Other</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en" ? "House Number" : "街號"}:
            </FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "House Number" : "街號"}
              value={streetNo}
              isDisabled={!hasEditPermissions}
              onChange={(e) => setStreetNo(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "Street Name" : "街名"}:</FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "Street Name" : "街名"}
              value={streetName}
              isDisabled={!hasEditPermissions}
              onChange={(e) => setStreetName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "City" : "市名"}:</FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "City" : "市名"}
              value={city}
              isDisabled={!hasEditPermissions}
              onChange={(e) => setCity(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "State" : "州名"}:</FormLabel>
            <Select
              placeholder={language === "en" ? "Select State" : "州名"}
              value={state}
              isDisabled={!hasEditPermissions}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="N/A">N/A</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en" ? "Zip Code" : "邮遞區號"}:
            </FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "Zip Code" : "邮遞區號"}
              value={zipCode}
              maxLength={5}
              isDisabled={!hasEditPermissions}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "Registerer" : "家主"}:</FormLabel>
            <Checkbox
              isChecked={registerer}
              isDisabled
              onChange={(e) => {
                setRegisterer(e.target.checked);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en" ? "Has Special Needs" : "特殊需求"}:
            </FormLabel>
            <Checkbox
              isChecked={hasSpecialNeeds}
              isDisabled={!hasEditPermissions}
              onChange={(e) => {
                setHasSpecialNeeds(e.target.checked);
              }}
            />
          </FormControl>
          {hasSpecialNeeds && (
            <FormControl>
              <FormLabel>
                {language === "en" ? "Special Needs" : "特殊需求"}
              </FormLabel>
              <Textarea
                value={specialNeeds}
                isDisabled={!hasEditPermissions}
                onChange={(e) => setSpecialNeeds(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl isRequired>
            <FormLabel>{language === "en" ? "Church" : "教會"}:</FormLabel>
            <Select
              placeholder={language === "en" ? "Select Church" : "教會"}
              value={church || ""}
              isDisabled={!hasEditPermissions}
              onChange={(e) => {
                setChurch(e.target.value || null);
                setChurchOther("");
              }}
              isRequired
            >
              {churchList.map((item) => (
                <option value={item._id} key={item._id}>
                  {`${item.name}: ${item.long_name}`}
                </option>
              ))}
              <option value="Other">
                {language === "en"
                  ? "Other (specify below)"
                  : "[Other (specify below)]"}
              </option>
            </Select>
          </FormControl>
          {church === "Other" && (
            <FormControl isRequired>
              <Input
                type="text"
                placeholder={language === "en" ? "Church" : "教會"}
                value={churchOther}
                isDisabled={!hasEditPermissions}
                onChange={(e) => setChurchOther(e.target.value)}
              />
            </FormControl>
          )}
          <FormControl>
            <FormLabel>
              {language === "en"
                ? "Do you require pickup from the airport?"
                : "[Do you require pickup from the airport?]"}
            </FormLabel>
            <Checkbox
              isChecked={airportPickupInfo != null}
              isDisabled={!hasEditPermissions}
              onChange={(e) => {
                if (e.target.checked) {
                  setAirportPickupInfo({
                    airlineName: "",
                    flightNumber: "",
                    arrivalAirportName: "",
                    arrivalDateTime: "",
                    additionalInfo: "",
                  });
                } else {
                  setAirportPickupInfo(null);
                }
              }}
            />
          </FormControl>
          {airportPickupInfo != null && (
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Arrival Airline"
                      : "[Arrival Airline]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportPickupInfo.airlineName}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportPickupInfo({
                        ...airportPickupInfo,
                        airlineName: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Arrival Flight No."
                      : "[Arrival Flight No.]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportPickupInfo.flightNumber}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportPickupInfo({
                        ...airportPickupInfo,
                        flightNumber: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Arrival Airport"
                      : "[Arrival Airport]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportPickupInfo.arrivalAirportName}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportPickupInfo({
                        ...airportPickupInfo,
                        arrivalAirportName: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Arrival Date and Time"
                      : "[Arrival Date and Time]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportPickupInfo.arrivalDateTime}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportPickupInfo({
                        ...airportPickupInfo,
                        arrivalDateTime: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Additional Info"
                      : "[Additional Info]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportPickupInfo.additionalInfo}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportPickupInfo({
                        ...airportPickupInfo,
                        additionalInfo: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </CardBody>
            </Card>
          )}
          <FormControl>
            <FormLabel>
              {language === "en"
                ? "Do you require airport dropoff?"
                : "[Do you require airport dropoff?]"}
            </FormLabel>
            <Checkbox
              isChecked={airportDropoffInfo != null}
              isDisabled={!hasEditPermissions}
              onChange={(e) => {
                if (e.target.checked) {
                  setAirportDropoffInfo({
                    airlineName: "",
                    flightNumber: "",
                    departureAirportName: "",
                    departureDateTime: "",
                    additionalInfo: "",
                  });
                } else {
                  setAirportDropoffInfo(null);
                }
              }}
            />
          </FormControl>
          {airportDropoffInfo != null && (
            <Card>
              <CardBody>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Departure Airline"
                      : "[Departure Airline]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportDropoffInfo.airlineName}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportDropoffInfo({
                        ...airportDropoffInfo,
                        airlineName: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Departure Flight No."
                      : "[Departure Flight No.]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportDropoffInfo.flightNumber}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportDropoffInfo({
                        ...airportDropoffInfo,
                        flightNumber: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Departure Airport"
                      : "[Departure Airport]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportDropoffInfo.departureAirportName}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportDropoffInfo({
                        ...airportDropoffInfo,
                        departureAirportName: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Departure Date and Time"
                      : "[Departure Date and Time]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportDropoffInfo.departureDateTime}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportDropoffInfo({
                        ...airportDropoffInfo,
                        departureDateTime: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Additional Info"
                      : "[Additional Info]"}
                  </FormLabel>
                  <Input
                    type="text"
                    value={airportDropoffInfo.additionalInfo}
                    isDisabled={!hasEditPermissions}
                    onChange={(e) =>
                      setAirportDropoffInfo({
                        ...airportDropoffInfo,
                        additionalInfo: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </CardBody>
            </Card>
          )}
          {isAdmin(user) && (
            <>
              <FormControl>
                <FormLabel>{language === "en" ? "Group" : "群組"}:</FormLabel>
                <Select
                  value={group || ""}
                  onChange={(e) => setGroup(e.target.value || null)}
                >
                  <option value="">
                    {language === "en" ? "No Group" : "無群組"}
                  </option>
                  {groupList.map((item) => (
                    <option value={item._id} key={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{language === "en" ? "Leader" : "群組"}:</FormLabel>
                <Select
                  value={leader}
                  onChange={(e) => setLeader(e.target.value === "true")}
                >
                  <option value="true">
                    {language === "en" ? "True" : "是"}
                  </option>
                  <option value="false">
                    {language === "en" ? "False" : "否"}
                  </option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{language === "en" ? "Dorm" : "宿舍"}:</FormLabel>
                <Select
                  value={dorm || ""}
                  isDisabled={
                    lodgingMealsDropdownVal === "Commuting - All Meals" ||
                    lodgingMealsDropdownVal ===
                      "Commuting - Pay Meals at Counter"
                  }
                  onChange={(e) => setDorm(e.target.value || null)}
                >
                  <option value="">
                    {language === "en" ? "No Dorm" : "無宿舍"}
                  </option>
                  {dormList.map((item) => (
                    <option value={item._id} key={item._id}>
                      {`${item.building?.name} [Floor ${item.floor}, Room ${item.room_number}]`}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {hasPermission(user, "edit_reference_data_roles") && (
            <>
              <FormControl>
                <FormLabel>{language === "en" ? "Role" : "角色"}:</FormLabel>
                <Select
                  value={userRole || ""}
                  onChange={(e) => {
                    setUserRole(e.target.value || null);
                    setRoleChurchId(null);
                  }}
                >
                  {roleList.map((item) => (
                    <option value={item._id} key={item._id}>
                      {`${item.name}: ${item.description}`}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {roleList
                .find((r) => r._id === userRole)
                ?.permissions?.some((p) => p.includes("for_church")) && (
                <FormControl>
                  <FormLabel>
                    {language === "en"
                      ? "Role church access"
                      : "[Role church access]"}
                    :
                  </FormLabel>
                  <Select
                    value={roleChurchId || ""}
                    onChange={(e) => setRoleChurchId(e.target.value || null)}
                    placeholder={language === "en" ? "Select" : "選項"}
                  >
                    {churchList.map((item) => (
                      <option value={item._id} key={item._id}>
                        {`${item.name}: ${item.long_name}`}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {isAdmin(user) &&
            !user?.role?.permissions?.includes("edit_reference_data_roles") && (
              <>
                <p>
                  <b>{language === "en" ? "Role: " : "角色: "}</b>
                  {roleList.find((r) => r._id === userRole)?.name || "None"}
                </p>
                <p>
                  <b>
                    {language === "en"
                      ? "Role Church Id: "
                      : "Role Church Id: "}
                  </b>
                  {churchList.find((c) => c._id === roleChurchId)?.long_name ||
                    "None"}
                </p>
              </>
            )}
          {user?.role?.permissions?.length == 0 && (
            <>
              <p>
                <b>{language === "en" ? "Group: " : "群組: "}</b>
                {groupList.find((g) => g._id === group)?.name || "None"}
              </p>
              <p>
                <b>{language === "en" ? "Leader: " : "組長: "}</b>
                {leader ? "True" : "False"}
              </p>
              <p>
                <b>{language === "en" ? "Dorm: " : "宿舍: "}</b>
                {dormList.find((d) => d._id === dorm)
                  ? `${
                      dormList.find((d) => d._id === dorm).building?.name
                    } [Floor ${
                      dormList.find((d) => d._id === dorm).floor
                    }, Room ${
                      dormList.find((d) => d._id === dorm).room_number
                    }]`
                  : "None"}
              </p>
            </>
          )}
          <FormControl>
            <FormLabel>
              {language === "en" ? "Lodging and Meals" : "住宿和三餐"}:
            </FormLabel>
            <Select
              placeholder={language === "en" ? "Select" : "選項"}
              value={lodgingMealsDropdownVal || ""}
              isDisabled={!hasEditPermissions}
              onChange={(e) => {
                setLodgingMealsDropdownVal(e.target.value || null);
                if (e.target.value === "Full Time") {
                  setLodging([...lodgingList.map((l) => l._id)]);
                  setMeals([...mealList.map((m) => m._id)]);
                  setLodgingEdit(false);
                  setMealsEdit(false);
                } else if (e.target.value === "Part Time") {
                  setLodging([]);
                  setMeals([]);
                  setLodgingEdit(true);
                  setMealsEdit(true);
                } else if (e.target.value === "Commuting - All Meals") {
                  setLodging([]);
                  setMeals([...mealList.map((m) => m._id)]);
                  setLodgingEdit(false);
                  setMealsEdit(false);
                  setDorm(null);
                } else if (
                  e.target.value === "Commuting - Pay Meals at Counter"
                ) {
                  setLodging([]);
                  setMeals([]);
                  setLodgingEdit(false);
                  setMealsEdit(true);
                  setDorm(null);
                }
              }}
            >
              {optionsList
                .sort((a, b) => a.val - b.val)
                .map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          {lodgingMealsDropdownVal === "Commuting - All Meals" && (
            <>
              <FormLabel>
                {language === "en" ? "Select No Meals" : "不選三餐"}:
              </FormLabel>
              <Checkbox
                isChecked={
                  lodgingMealsDropdownVal === "Commuting - All Meals" &&
                  meals.length == 0
                }
                isDisabled={!hasEditPermissions}
                onChange={(e) => {
                  if (e.target.checked) {
                    setMeals([]);
                  } else {
                    setMeals([...mealList.map((m) => m._id)]);
                  }
                }}
              />
            </>
          )}
          <FormControl>
            <FormLabel>{language === "en" ? "Lodging" : "住宿"}:</FormLabel>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    {lodgingList.map((item) => (
                      <Th key={item._id}>
                        {new Date(item.date).toISOString().split("T")[0]}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {lodgingList.map((item) => (
                      <Td key={item._id}>
                        <Checkbox
                          id={item._id}
                          isChecked={lodging.includes(item._id)}
                          isDisabled={!hasEditPermissions || !lodgingEdit}
                          onChange={(e) => {
                            if (
                              e.target.checked &&
                              !lodging.includes(item._id)
                            ) {
                              setLodging([...lodging, item._id]);
                            } else {
                              setLodging(lodging.filter((l) => l !== item._id));
                            }
                          }}
                        />
                      </Td>
                    ))}
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </FormControl>
          <FormControl>
            <FormLabel>{language === "en" ? "Meals" : "三餐"}:</FormLabel>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th></Th>
                    {Object.keys(mealMap)
                      .sort(
                        (a, b) => new Date(a).getTime() - new Date(b).getTime()
                      )
                      .map((date) => (
                        <Th key={date}>
                          {new Date(date).toISOString().split("T")[0]}
                        </Th>
                      ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {mealOrder.map((mealName) => (
                    <Tr key={mealName}>
                      <Td>{mealName}</Td>
                      {Object.keys(mealMap)
                        .sort(
                          (a, b) =>
                            new Date(a).getTime() - new Date(b).getTime()
                        )
                        .map((date) => (
                          <Td key={date}>
                            {mealMap[date][mealName] && (
                              <Checkbox
                                id={mealMap[date][mealName]}
                                isChecked={meals.includes(
                                  mealMap[date][mealName]
                                )}
                                isDisabled={!hasEditPermissions || !mealsEdit}
                                onChange={(e) => {
                                  if (
                                    e.target.checked &&
                                    !meals.includes(mealMap[date][mealName])
                                  ) {
                                    setMeals([
                                      ...meals,
                                      mealMap[date][mealName],
                                    ]);
                                  } else {
                                    setMeals(
                                      meals.filter(
                                        (l) => l !== mealMap[date][mealName]
                                      )
                                    );
                                  }
                                }}
                              />
                            )}
                          </Td>
                        ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </FormControl>
          <Box alignSelf="center">
            <Button
              type="button"
              alignSelf="center"
              margin="0px 20px"
              onClick={() => navigate(-1)}
            >
              {language === "en" ? "Back" : "回上頁"}
            </Button>
            {(!user || isAdmin(user) || user?.registerer) && (
              <Button
                colorScheme="blue"
                type="submit"
                alignSelf="center"
                margin="0px 20px"
              >
                {language === "en" ? "Submit" : "發出"}
              </Button>
            )}
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default RegistrationForm;
