import React, { useContext, useEffect, useState } from "react";
import api from "../../utils/axios";
import { Heading, Box, Spinner } from "@chakra-ui/react";
import RegistrationForm from "../../components/registration-form/RegistrationForm";
import { UserContext } from "../../context/UserContext";

function Signup() {
  const { language } = useContext(UserContext);
  const [registrationIsOpen, setRegistrationIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        setRegistrationIsOpen(res.data[0].isOpen);
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <>
        <Heading textAlign="center" margin="20px">
          {language === "en" ? "Sign Up" : "註冊登记"}
        </Heading>
        <Box display="flex" justifyContent="center" marginTop="50px">
          <Spinner size="xl" />
        </Box>
      </>
    );
  }

  return (
    <>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Sign Up" : "註冊登记"}
      </Heading>
      {registrationIsOpen ? (
        <RegistrationForm />
      ) : (
        <Heading as="h3" size="md" textAlign="center">
          Registration is not open yet.
        </Heading>
      )}
    </>
  );
}

export default Signup;
