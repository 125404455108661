import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import moment from "moment-timezone";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminManageEvents() {
  const { user, language } = useContext(UserContext);

  const [events, setEvents] = useState([]);

  function fetchReferenceData() {
    api.get("/api/events").then((res) => {
      setEvents(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleDelete(d) {
    if (!hasPermission(user, "edit_reference_data_events")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to delete events.");
      return;
    }

    if (
      // eslint-disable-next-line no-alert
      window.confirm("Are you sure you wish to delete this event?")
    ) {
      try {
        await api.delete(`/api/events/${d._id}`);
        fetchReferenceData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("Event deleted");
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Events
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Event Name</Th>
              <Th>Start Date/Time</Th>
              <Th>End Date/Time</Th>
              <Th>Registration Open</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {events &&
              events.map((event) => (
                <Tr key={event._id}>
                  <Td>{event.eventName}</Td>
                  <Td>
                    {event.eventStartDate
                      ? moment(event.eventStartDate)
                          .tz("America/New_York")
                          .format("L, LT z")
                      : "None"}
                  </Td>
                  <Td>
                    {event.eventEndDate
                      ? moment(event.eventEndDate)
                          .tz("America/New_York")
                          .format("L, LT z")
                      : "None"}
                  </Td>
                  <Td>{event.isOpen ? "Yes" : "No"}</Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_events")
                          ? `/admin/reference-data/events/${event._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_events")
                      }
                    >
                      {language === "en" ? "Update" : "[Update]"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(event)}
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_events")
                      }
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          Back
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_events")
              ? "/admin/reference-data/events/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_events")}
        >
          {language === "en" ? "Add Event" : "[Add Event]"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageEvents;
