import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
} from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";
import DownloadUsersCSVButton from "../../components/DownloadUsersCSVButton";
import PrintableUserLabelsDocumentButton from "../../components/PrintableUserLabelsDocumentButton";
import PrintableFamilyLabelsButton from "../../components/PrintableFamilyLabelsButton";
import AdminUserFilter from "../../components/AdminUserFilter";
import AdminUserTableFooter from "../../components/AdminUserTableFooter";
import { hasPermission } from "../../utils/helpers";

function AdminRegistrationHome() {
  const { user, language } = useContext(UserContext);
  const navigate = useNavigate();

  const [filteredUsers, setFilteredUsers] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumberChange, setPageNumberChange] = useState(false);

  function prettyPrintChurch(u) {
    if (!u.church) {
      return "Other: " + (u.churchOther || "");
    }

    return u.church?.name || u.church?.long_name
      ? `${u.church?.name}: ${u.church?.long_name}`
      : "";
  }

  function handleNavigateYourFamily() {
    navigate(`/admin/registration/family/${user.familyId}`);
  }

  function handleViewFamily(familyId) {
    navigate(`/admin/registration/family/${familyId}`);
  }

  function handleUpdate(id) {
    navigate(`/admin/registration/${id}`);
  }

  async function handleDelete(u) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you wish to delete user ${u.firstName} ${u.lastName}?`
      )
    ) {
      try {
        await api.delete(`/api/users/${u._id}`);
        setFilteredUsers(filteredUsers.filter((user) => user._id !== u._id));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("User deleted");
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Registration" : "登记注册"}
      </Heading>
      <Button onClick={() => handleNavigateYourFamily()}>
        {language == "en" ? "View Your Family" : "查看您的家人"}
      </Button>
      <AdminUserFilter
        setFilteredUsers={setFilteredUsers}
        setPageLoading={setPageLoading}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageTotal={setPageTotal}
        pageNumberChange={pageNumberChange}
      />
      {pageLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <TableContainer margin={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th scope="col">First Name</Th>
                  <Th scope="col">Last Name</Th>
                  <Th scope="col">Chin. Name</Th>
                  <Th scope="col">Email</Th>
                  <Th scope="col">Attending</Th>
                  <Th scope="col">Fam. Id</Th>
                  <Th scope="col">Group</Th>
                  <Th scope="col">Church</Th>
                  <Th scope="col">Age</Th>
                  <Th scope="col">Grade</Th>
                  <Th scope="col"></Th>
                  <Th scope="col"></Th>
                  <Th scope="col"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers &&
                  filteredUsers.map((u) => (
                    <Tr key={u._id}>
                      <Td>{u.firstName}</Td>
                      <Td>{u.lastName}</Td>
                      <Td>{u.chineseName}</Td>
                      <Td>{u.email}</Td>
                      <Td>
                        {u.registrationStatus === "Not Attending"
                          ? "No"
                          : "Yes"}
                      </Td>
                      <Td>{u.familyId}</Td>
                      <Td>{u.groupName}</Td>
                      <Td>{prettyPrintChurch(u)}</Td>
                      <Td>{u.age >= 0 ? u.age : ""}</Td>
                      <Td>{u.grade}</Td>
                      <Td>
                        <Button onClick={() => handleViewFamily(u.familyId)}>
                          View Family
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          colorScheme="yellow"
                          onClick={() => handleUpdate(u._id)}
                        >
                          Update
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          isDisabled={
                            u._id === user?._id ||
                            !hasPermission(user, "delete_users")
                          }
                          colorScheme="red"
                          onClick={() => handleDelete(u)}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <AdminUserTableFooter
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageTotal={pageTotal}
            pageNumberChange={pageNumberChange}
            setPageNumberChange={setPageNumberChange}
          />
          <Flex>
            <DownloadUsersCSVButton attendingUsers={true} />{" "}
            <DownloadUsersCSVButton attendingUsers={false} />
          </Flex>
          <Flex>
            <PrintableUserLabelsDocumentButton />
            <PrintableFamilyLabelsButton />
          </Flex>
        </>
      )}
    </Box>
  );
}

export default AdminRegistrationHome;
