import React, { useContext } from "react";
import api from "../utils/axios";
import { saveAs } from "file-saver";
import {
  pdf,
  Font,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  useDisclosure,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text as ChakraText,
} from "@chakra-ui/react";
import { UserContext } from "../context/UserContext";
import mediumFontSrc from "../fonts/NotoSansSC-Medium.otf";
import regularFontSrc from "../fonts/NotoSansSC-Regular.otf";
import { hasPermission } from "../utils/helpers";
import { prettyPrintDorm } from "../utils/PrettyPrints";

// Default font does not correctly display chinese characters
Font.register({ family: "NotoSansSC-Medium", src: mediumFontSrc });
Font.register({ family: "NotoSansSC-Regular", src: regularFontSrc });

// PDFs generated with 72 dpi by default
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 72,
  },
  cardColumn: {
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
    fontFamily: "NotoSansSC-Regular",
    padding: 10,
    width: 500,
  },
  title: {
    fontSize: 24,
    marginTop: 5,
    marginBottom: 20,
    fontFamily: "NotoSansSC-Regular",
    justifySelf: "flex-end",
  },
  subtitle: {
    fontSize: 18,
    marginTop: 5,
    marginBottom: 10,
    fontFamily: "NotoSansSC-Regular",
    justifySelf: "flex-end",
  },
  dorm: {
    fontSize: 12,
    fontFamily: "NotoSansSC-Regular",
  },
});

function PrintableFamilyLabelsDocument({ families, eventDesc }) {
  function getChurch(users) {
    if (!users) {
      return "Church: None";
    }

    let registerer = users.find((user) => user.registerer);
    if (!registerer) {
      registerer = users[0];
    }

    if (!registerer.church) {
      return "Church: None";
    }

    return `Church: ${registerer.church?.name || ""} - ${
      registerer.church?.long_name || ""
    }`;
  }

  function createPage(familyId, users) {
    if (!users) {
      return null;
    }

    // if (users.length > 10) {
    //   return (
    //     <View style={styles.container}>
    //       <Text style={styles.subtitle}>{getChurch(users)}</Text>
    //       <Text style={styles.subtitle}>{`Family ID: ${familyId}`}</Text>
    //       {users.map((user) => (
    //         <div style={styles.cardContainer} key={user._id}>
    //           <Text style={styles.dorm}>{`${user.firstName || ""} ${
    //             user.lastName || ""
    //           } ${user.chineseName || ""}`}</Text>
    //           <Text style={styles.dorm}>{prettyPrintDorm(user)}</Text>
    //         </div>
    //       ))}
    //     </View>
    //   );
    // }

    if (users.length <= 8) {
      return (
        <Page key={familyId.toString()}>
          <View style={styles.container}>
            {eventDesc ? <Text style={styles.title}>{eventDesc}</Text> : null}
            <Text style={styles.title}>{getChurch(users)}</Text>
            <Text style={styles.title}>{`Family ID: ${familyId}`}</Text>
            {users.map((user) => (
              <div style={styles.cardContainer} key={user._id}>
                <Text>{`${user.firstName || ""} ${user.lastName || ""} ${
                  user.chineseName || ""
                }`}</Text>
                <Text style={styles.dorm}>{prettyPrintDorm(user)}</Text>
              </div>
            ))}
          </View>
        </Page>
      );
    }

    // make pages of at most 8 users each
    const pages = [];
    const church = getChurch(users);
    for (let i = 0; i < users.length; i += 8) {
      const pageUsers = users.slice(i, i + 8);
      pages.push(
        <Page key={`${familyId}_${i}`}>
          <View style={styles.container}>
            {eventDesc ? <Text style={styles.title}>{eventDesc}</Text> : null}
            <Text style={styles.title}>{church}</Text>
            <Text style={styles.title}>{`Family ID: ${familyId}`}</Text>
            {pageUsers.map((user) => (
              <div style={styles.cardContainer} key={user._id}>
                <Text>{`${user.firstName || ""} ${user.lastName || ""} ${
                  user.chineseName || ""
                }`}</Text>
                <Text style={styles.dorm}>{prettyPrintDorm(user)}</Text>
              </div>
            ))}
          </View>
        </Page>
      );
    }

    return pages;
  }

  return (
    <Document>
      {families &&
        families.map(({ familyId, users }) => createPage(familyId, users))}
    </Document>
  );
}

function PrintableFamilyLabelsButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useContext(UserContext);
  const [familyFilter, setFamilyFilter] = React.useState("");

  async function handleDownload(isFiltering) {
    let families = (await api.get("/api/users/families")).data;

    if (isFiltering) {
      let selectedFamilyIds = [];
      const familyIds = familyFilter.toLowerCase().split(",");
      for (const familyId of familyIds) {
        if (familyId.trim().length > 0) {
          selectedFamilyIds.push(familyId.trim());
        }
      }

      let filteredFamilies = [];
      for (const family of families) {
        if (family.users.length === 0) {
          continue;
        }

        if (selectedFamilyIds.includes(family.familyId)) {
          filteredFamilies.push(family);
        }
      }

      families = filteredFamilies;
    }

    if (families.length === 0) {
      alert("No families found.");
      return;
    }

    let eventDesc = "";
    await api.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        const eventName = res.data[0].eventName;
        const chineseEventName = res.data[0].eventName2;
        const eventStartYear = new Date(
          res.data[0].eventStartDate
        ).getFullYear();
        const eventEndYear = new Date(res.data[0].eventEndDate).getFullYear();
        const eventYear =
          eventStartYear == eventEndYear
            ? eventStartYear
            : eventStartYear + "-" + eventEndYear;

        eventDesc = `${eventYear} ${eventName} ${chineseEventName}`;
      }
    });

    // For testing purposes
    // for (let i = 0; i < 34; i++) {
    //   families[0].users.push({ ...families[0].users[0] });
    // }

    // Sort by registerer's church, then by last name
    families.sort((a, b) => {
      const aChurch =
        a.users.find((user) => user.registerer)?.church?.long_name || "";
      const bChurch =
        b.users.find((user) => user.registerer)?.church?.long_name || "";

      if (aChurch !== bChurch) {
        return aChurch.localeCompare(bChurch);
      }

      const aLastName = a.users.find((user) => user.registerer)?.lastName || "";
      const bLastName = b.users.find((user) => user.registerer)?.lastName || "";

      return aLastName.localeCompare(bLastName);
    });

    // This loads the pdf after the user has clicked on the button rather than loading when the page renders
    const blob = await pdf(
      <PrintableFamilyLabelsDocument
        families={families}
        eventDesc={eventDesc}
      />
    ).toBlob();
    saveAs(blob, "envelope_printable_labels.pdf");
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Printable Family Labels</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ChakraText>
              This will generate a PDF of family envelope labels. If you want to
              print only a specific set of families, enter a comma separated
              list of family IDs.
            </ChakraText>
            <Input
              type="text"
              value={familyFilter}
              onChange={(e) => setFamilyFilter(e.target.value)}
              marginTop={3}
              placeholder="Ex. 101,102,103,104"
            />
          </ModalBody>
          <ModalFooter>
            <>
              <Button
                variant="ghost"
                colorScheme="teal"
                margin={0}
                onClick={() => handleDownload(false)}
              >
                Print all families
              </Button>

              <Button
                variant="ghost"
                margin={3}
                onClick={() => handleDownload(true)}
              >
                Print filtered families
              </Button>
            </>
            <Button colorScheme="blue" margin={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Button
        onClick={onOpen}
        margin={2}
        marginBottom={10}
        isDisabled={!hasPermission(user, "download")}
      >
        Download Envelope Labels
      </Button>
    </>
  );
}

export default PrintableFamilyLabelsButton;
