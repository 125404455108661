import { useContext } from "react";
import {
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  HStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";

import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
} from "@chakra-ui/react";

import { ExternalLinkIcon } from "@chakra-ui/icons";

export function EastLandingPage({ registrationIsOpen }) {
  const { language } = useContext(UserContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="center"
      marginLeft={20}
      marginRight={20}
    >
      <Stack direction="column" spacing={7}>
        <Heading as="h4" size="md" textAlign="center">
          {language === "en"
            ? registrationIsOpen
              ? `Welcome to East Coast Christian Conference Registration!`
              : `Registration is closed`
            : registrationIsOpen
            ? `歡迎您来到美東基督徒追求聚會註册網站!`
            : `特會報名註册已停止`}
        </Heading>
        <Heading as="h4" size="md" color="red" textAlign="center">
          {language === "en"
            ? registrationIsOpen
              ? `Thank the Lord for your interest in the conference. We have reached maximum capacity, so all new registrations will be placed on the waiting list. When rooms become available, you will receive an email to confirm your acceptance. After you register, please check your inbox and spam folders periodically for further notice from us.`
              : ``
            : registrationIsOpen
            ? `感谢主,您對美東特會的關注. 報名的名額已滿. 此後報名將被排在後補名單中. 若有了空位, 您會收到須要您確認参加的電邮. 因此報了名後, 請不時地查看是否收到特会發給您的的電邮, 須要您㸃入確認以完成報名.`
            : ``}
        </Heading>
        <Text>
          {language === "en"
            ? registrationIsOpen
              ? `By the grace of the Lord, brothers/sisters from several assemblies in the east coast tri-state areas have decided to hold a conference, East Coast Christian Conference, to seek together the eternal purpose of God and the recovery of His ways through the church.`
              : ``
            : registrationIsOpen
            ? `靠主的恩典，來自美國東岸三州地區的幾處聚會的弟兄姊妹決定舉辦東岸基督徒特會，共同尋求神永遠旨意, 並主在 祂教會中恢復的道路。`
            : ``}
        </Text>
        {registrationIsOpen ? (
          <>
            <Text>
              {language === "en"
                ? `Some details about this conference:`
                : `有關本次特會的一些詳細資訊:`}
            </Text>
            <UnorderedList spacing={1}>
              <ListItem marginLeft={30}>
                {language === "en"
                  ? `The theme of the conference: "God's creation and the way of recovery".`
                  : `特會的主題: “神的創造和恢復的道路”。`}
              </ListItem>
              <ListItem marginLeft={30}>
                {language === "en"
                  ? `Speakers: Brother Godwin Sun and Brother Philip Jong.`
                  : `講員: 為主作出口的孫國鐸和鍾理恩弟兄。`}
              </ListItem>
              <ListItem marginLeft={30}>
                {language === "en"
                  ? `Time/Date: The national holiday weekend from 2:00 afternoon July 4th to noon after lunch July 7th, 2024 this year (7/4/24 - 7/7/24).`
                  : `時間: 國慶週末7月4日下午2:00開始報到, 至2024年7月7日中午午餐後 (7/4/24 - 7/7/24)`}
              </ListItem>
              <ListItem marginLeft={30}>
                {language === "en"
                  ? `Kutztown University, PA.`
                  : `場地: 賓州庫茲敦大學(Kutztown University, PA).`}
              </ListItem>
            </UnorderedList>
            <Text>
              {language === "en"
                ? `Registration website [`
                : `本次特會的註冊網站 [`}
              <Link
                href="https://eccc.iselinchristianassembly.org"
                isExternal
                style={{ color: "red" }}
              >
                https://eccc.iselinchristianassembly.org{" "}
                <ExternalLinkIcon mx="2px" />
              </Link>
              {language === "en"
                ? `] for this conference will be open for registration from 9:00 am 4/13 to midnight 5/31.`
                : `] 將於2024年4月13日星期六上午9:00至5月31日午夜開放註冊。`}
            </Text>
            <Text>
              {language === "en"
                ? `Registration Process - When you have filled out all information on the registration page and have submitted it, your registration will be put on “To Be Confirmed” status initially. You will receive an email from us to inform you whether you are accepted to the conference.`
                : `註冊流程 - 當您填寫完註冊頁面上的所有資訊並提交後，您的註冊最初將處於「待確認」狀態。我们将通过电子邮件通知您是否被接受參加特會。`}
            </Text>
            <UnorderedList spacing={1}>
              <ListItem marginLeft={30}>
                {language === "en"
                  ? `If accepted, your registration is completed.`
                  : `如果被接受，您的註冊即完成。`}
              </ListItem>
              <ListItem marginLeft={30}>
                {language === "en"
                  ? `If you are being put on the waiting list, please look out for the email from us in your Inbox or Spam folder. As rooms become available, we will contact you via email with a link to click to be accepted.`
                  : `如果您被列入後補名單，请注意查收我们发送到您的收件箱的电子邮件, 若沒有找到此邮件, 請察看垃圾邮件文件箱。一旦有了空位，我們將透過電子郵件與您聯繫，并提供一个链接, 供您点入以完成登记手续。`}
              </ListItem>
            </UnorderedList>
            <Text>
              {language === "en"
                ? `Due to the capacity limit for the number of attendees on the campus, we will give priority to those who attend full time and are from the east coast.  The cost for the conference will be paid for by the voluntary love offerings of the saints. For reference purpose, the conference charges per person are listed as follows:`
                : `由於學校的住宿和教室有人數上的限制, 我們將優先考慮來自東岸的全程参加者。特會的費用將由聖徒自願的愛心奉獻支付。以下所列個人的全程费用僅供參考:`}
            </Text>
          </>
        ) : (
          <>
            <Text mt="-50">
              {language === "en"
                ? `By God’s grace, we will soon hold the East Coast Christian Conference at Kutztown University, PA from July 4th to July 7th. Please read in detail this home page for check-in information.`
                : `靠主的恩典, 我們即將舉行美國東岸基督徒追求聚會, 時間從七月四日到七月七日 (星期四至星期天). 有關此特會報到的資料請詳閲此首頁。`}
            </Text>

            <Text marginLeft={30}>
              {language === "en"
                ? `The theme of the conference: "God's creation and the way of recovery".`
                : `特會的主題: “神的創造和恢復的道路”。 講員: 為主作出口的孫國鐸和鍾理恩弟兄。`}
            </Text>
            <Text marginLeft={30} mt="-5">
              {language === "en"
                ? `Speakers: Brother Godwin Sun and Brother Philip Jong.`
                : `講員: 為主作出口的孫國鐸和鍾理恩弟兄。`}
            </Text>

            {/* ---- Conference Schedule ---- */}

            <TableContainer marginLeft={10} w={[300, 400, 500, 900, 1200]}>
              <Table variant="simple" size="sm">
                <Thead style={{ fontWeight: "bold" }}>
                  {/* ----- Schedule Headings ----- */}
                  <Tr
                    bg="lightGrey"
                    borderBottom="3px solid black"
                    align="center"
                  >
                    <Th
                      borderTop="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? <>Time</> : <>時間</>}
                    </Th>
                    <Th
                      borderTop="1px solid black"
                      borderLeft="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          Thursday
                          <br />
                          7/4
                        </>
                      ) : (
                        <>
                          星期四
                          <br />
                          7/4
                        </>
                      )}
                    </Th>
                    <Th
                      borderTop="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {/* {language === "en" ? `Friday 7/5` : `星期五 7/5`} */}
                      {language === "en" ? (
                        <>
                          Friday
                          <br />
                          7/5
                        </>
                      ) : (
                        <>
                          星期五
                          <br />
                          7/5
                        </>
                      )}
                    </Th>
                    <Th
                      borderTop="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {/* {language === "en" ? `Saturday 7/6` : `星期六 7/6`} */}
                      {language === "en" ? (
                        <>
                          Saturday
                          <br />
                          7/6
                        </>
                      ) : (
                        <>
                          星期六
                          <br />
                          7/6
                        </>
                      )}
                    </Th>
                    <Th
                      borderTop="1px solid black"
                      borderLeft="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {/* {language === "en" ? `Sunday 7/7` : `星期日 7/7`} */}
                      {language === "en" ? (
                        <>
                          Sunday
                          <br />
                          7/7
                        </>
                      ) : (
                        <>
                          星期日
                          <br />
                          7/7
                        </>
                      )}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* ----- 1st Row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          7:00
                          <br /> | <br />
                          7:45
                        </>
                      ) : (
                        <>
                          7:00
                          <br /> | <br />
                          7:45
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Morning Prayer` : `晨 禱`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* --- Second row ---- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          7:45
                          <br /> | <br />
                          8:45
                        </>
                      ) : (
                        <>
                          7:45
                          <br /> | <br />
                          8:45
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Breakfast` : `早 餐`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ---- Third row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="0px solid lightGrey"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          9:30
                          <br /> | <br />
                          11:00
                        </>
                      ) : (
                        <>
                          9:30
                          <br /> | <br />
                          11:00
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Welcome` : `歡 迎`}
                    </Td>

                    <Td
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en"
                        ? `Message - Philip Jong`
                        : `信息 - 鍾理恩`}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en"
                        ? `Message - Philip Jong`
                        : `信息 - 鍾理恩`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        textWrap: "wrap",
                      }}
                    >
                      {language === "en" ? (
                        <>
                          9:00 - 10:00
                          <br />
                          Breaking Bread
                        </>
                      ) : (
                        <>
                          9:00 - 10:00
                          <br />
                          擘餅
                        </>
                      )}
                    </Td>
                  </Tr>
                  {/* ---- Fourth Row ------ */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        textWrap: "wrap",
                      }}
                    >
                      {language === "en" ? (
                        <>
                          10:15 - 11:30 <br />
                          Message - Philip Jong
                        </>
                      ) : (
                        <>
                          10:15 - 11:30 <br />
                          信息 - 鍾理恩
                        </>
                      )}
                    </Td>
                  </Tr>
                  {/* ----- Fifth Row - Place Holder ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid lightGrey"
                      borderLeft="1px solid black"
                      width={18}
                      pb="0"
                      style={{
                        textAlign: "center",
                        verticalAlign: "bottom",
                        textWrap: "wrap",
                      }}
                    >
                      {language === "en" ? <>11:00</> : <>11:00</>}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>

                    <Td
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ----- Sixth Row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      pt="0"
                      style={{
                        textAlign: "center",
                        verticalAlign: "top",
                        textWrap: "wrap",
                      }}
                    >
                      {language === "en" ? (
                        <>
                          |<br />
                          1:00
                        </>
                      ) : (
                        <>
                          |<br />
                          1:00
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Lunch` : `午 餐`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ---- Seventh Row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          1:00
                          <br /> | <br />
                          2:30
                        </>
                      ) : (
                        <>
                          1:00
                          <br /> | <br />
                          2:30
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      width={32}
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        textWrap: "wrap",
                      }}
                    >
                      {language === "en" ? (
                        <>
                          2:00 - 7:00
                          <br />
                          Check in
                        </>
                      ) : (
                        <>
                          2:00 - 7:00
                          <br />
                          報到
                        </>
                      )}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      pr="0px"
                      style={{ textAlign: "right", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Break` : `休`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      width={60}
                      style={{ textAlign: "left", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : `息`}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en"
                        ? `Check out before 2:00`
                        : `2:00 前離營`}
                    </Td>
                  </Tr>
                  {/* ---- Eighth Row ---- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          2:45
                          <br /> | <br />
                          4:00
                        </>
                      ) : (
                        <>
                          2:45
                          <br /> | <br />
                          4:00
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Group Fellowship` : `小組交通`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Group Fellowship` : `小組交通`}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ---- Nineth Row ---- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          4:00
                          <br /> | <br />
                          6:00
                        </>
                      ) : (
                        <>
                          4:00
                          <br /> | <br />
                          6:00
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Dinner` : `晚餐`}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      pr="0px"
                      style={{ textAlign: "right", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Dinner` : `晚`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "left", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : `餐`}
                    </Td>
                    <Td
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ---- Tenth Row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          7:00
                          <br /> | <br />
                          8:30
                        </>
                      ) : (
                        <>
                          7:00
                          <br /> | <br />
                          8:30
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en"
                        ? `Message - Godwin Sun`
                        : `信息 - 孫國鐸`}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en"
                        ? `Message - Godwin Sun`
                        : `信息 - 孫國鐸`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en"
                        ? `Message - Godwin Sun`
                        : `信息 - 孫國鐸`}
                    </Td>
                    <Td
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ----- 11th Row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          9:00
                          <br /> | <br />
                          10:00
                        </>
                      ) : (
                        <>
                          9:00
                          <br /> | <br />
                          10:00
                        </>
                      )}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Free Time` : `自由時間`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={40}
                      pr="0px"
                      style={{ textAlign: "right", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          Singspiration
                          <br />
                          Free
                        </>
                      ) : (
                        `自 由`
                      )}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={40}
                      pl="5px"
                      style={{ textAlign: "left", textWrap: "wrap" }}
                    >
                      {language === "en" ? (
                        <>
                          Fellowship
                          <br />
                          Time
                        </>
                      ) : (
                        `交 通`
                      )}
                    </Td>
                    <Td
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                  {/* ----- 12th Row ----- */}
                  <Tr>
                    <Td
                      bg="lightGrey"
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={18}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? <>10:30</> : <>10:30</>}
                    </Td>
                    <Td
                      borderLeft="1px solid black"
                      borderBottom="1px solid black"
                      width={32}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Light Out` : `熄燈`}
                    </Td>

                    <Td
                      borderBottom="1px solid black"
                      borderLeft="1px solid black"
                      width={60}
                      style={{ textAlign: "right", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Light` : `熄`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "left", textWrap: "wrap" }}
                    >
                      {language === "en" ? `Out` : `燈`}
                    </Td>
                    <Td
                      borderBottom="1px solid black"
                      borderRight="1px solid black"
                      width={60}
                      style={{ textAlign: "center", textWrap: "wrap" }}
                    >
                      {language === "en" ? `` : ``}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Text as="b">
              {language === "en"
                ? `Check-in procedures at Kutztown University:`
                : `特會場地報到,須要注意的事項:`}
            </Text>
            <UnorderedList spacing={2} mt="-5">
              <HStack w={[300, 400, 500, 900, 1200, 1500]}>
                <ListItem marginLeft={30}>
                  {/* Empty to get just a bullet symbol on page */}
                </ListItem>
                <Text as="b">{language === "en" ? `Address:` : `地址:`}</Text>
                <Text>15200 Kutztown Rd. Kutztown, PA 19530</Text>
              </HStack>
              <HStack w={[300, 400, 500, 900, 1500]}>
                <ListItem marginLeft={30}>
                  {/* Empty to get just a bullet symbol on page */}
                </ListItem>
                <Text as="b">
                  {language === "en" ? `Check-in Time:` : `報到時間:`}
                </Text>
                <Text>
                  {language === "en"
                    ? `3:00 pm - 7:00 pm July 4th, 7/4, Thursday`
                    : `星期四 7/4 - 從下午 3:00pm 至 7:00pm`}
                </Text>
              </HStack>
              <HStack w={[300, 400, 500, 900, 1500]}>
                <ListItem marginLeft={30}>
                  {/* Empty to get just a bullet symbol on page */}
                </ListItem>
                <Text as="b">
                  {language === "en" ? `Arrival:` : `到了場地:`}
                </Text>
                <Text>
                  {language === "en"
                    ? `Upon arrival, please follow the ECCC signs to the registration check-in desk to check in`
                    : `請先按照特會路標指示, 至報到處辦理報到`}
                </Text>
              </HStack>
              <HStack w={[300, 400, 500, 900, 1500]}>
                <ListItem marginLeft={30}>
                  {/* Empty to get just a bullet symbol on page */}
                </ListItem>
                <Text as="b">
                  {language === "en" ? `Envelope:` : `公文袋:`}
                </Text>
                <Text>
                  {language === "en"
                    ? `After receiving the registration envelope and room keys/meal cards, please carry your luggage to the room and check the furniture and bathroom. Report to the registration desk for any damage or out of order utilities`
                    : `拿到了報名資料公文袋, 並房間鑰匙後, 再將行李搬至房間, 並請檢查傢俱及衛生浴室設備。若有已損坯或故障的, 請到報到處告知`}
                </Text>
              </HStack>
              <HStack w={[300, 400, 500, 900, 1500]}>
                <ListItem marginLeft={30}>
                  {/* Empty to get just a bullet symbol on page */}
                </ListItem>
                <Text as="b">
                  {language === "en" ? `Amenities:` : `校方预備之用品:`}
                </Text>
                <Text>
                  {language === "en"
                    ? `School provides each attendee with 2 sheets, 2 towels, wash cloth, pillowcase, pillow, and a blanket. Each room has an A/C and heater unit. Please bring your personal hygiene items`
                    : `校方為每人提供 - 2張床單, 2個浴巾, 1個洗臉毛巾, 1組枕頭套和枕頭, 和 1條毛毯。每個房間裝有冷暖氣。請自備個人用之衛生浴室用品`}
                </Text>
              </HStack>
              <HStack w={[300, 400, 500, 900, 1500]}>
                <ListItem marginLeft={30}>
                  {/* Empty to get just a bullet symbol on page */}
                </ListItem>
                <Text as="b">{language === "en" ? `Late:` : `遲到:`}</Text>
                <Text>
                  {language === "en"
                    ? `If not able to arrive in time for check in, please contact registration desk beforehand: contact numbers are listed in the confirmation email sent to all the accepted attendees.`
                    : `若不能趕在報到時間內報到, 請事先通知註冊組 - 電話号码列在通知那些報了名,並確認參加者的電邮裏`}
                </Text>
              </HStack>
            </UnorderedList>
            <Text as="b">
              {language === "en"
                ? `To attend meetings: `
                : `每個人須要攜帶的聚會用物:`}
            </Text>
            <Text mt="-5" ml="30">
              {language === "en"
                ? `Please bring Bible, Hymnary (published by Testimony Publications USA), pen, and notebook`
                : `聖經, 聖徒詩歌(若有此詩歌版本), 筆記本, 及筆`}
            </Text>
            <Text as="b">
              {language === "en"
                ? `Live Stream Message Links:`
                : `特會提供講道信息的即時線上視頻如下:`}
            </Text>
            <Text mt="-5" ml="30" w={[300, 400, 500, 900, 1500]}>
              <Link
                href="https://youtube.com/live/JqxtgDMbbEs"
                // isExternal
                style={{ color: "blue" }}
              >
                {language === "en"
                  ? `Brother Godwin Sun - Message 1`
                  : `孫國鐸弟兄信息 - 線上視頻鏈接-1`}
                {`  `}
                {/* <ExternalLinkIcon mx="2px" /> */}
              </Link>
              <br />
              <Link
                href="https://youtube.com/live/BAQynAa4Ncc"
                // isExternal
                style={{ color: "blue" }}
              >
                {language === "en"
                  ? `Brother Godwin Sun - Message 2`
                  : `孫國鐸弟兄信息 - 線上視頻鏈接-2`}
                {`  `}
                {/* <ExternalLinkIcon mx="2px" /> */}
              </Link>
              <br />
              <Link
                href="https://youtube.com/live/7Xh13fqCWyo"
                // isExternal
                style={{ color: "blue" }}
              >
                {language === "en"
                  ? `Brother Godwin Sun - Message 3`
                  : `孫國鐸弟兄信息 - 線上視頻鏈接-3`}
                {`  `}
                {/* <ExternalLinkIcon mx="2px" /> */}
              </Link>
              <br />
              <Link
                href="https://youtube.com/live/zLdnmSZ0A6Q"
                // isExternal
                style={{ color: "blue" }}
              >
                {language === "en"
                  ? `Brother Philip Jong - Message 1`
                  : `鍾理恩弟兄信息 - 線上視頻鏈接-1`}
                {`  `}
                {/* <ExternalLinkIcon mx="2px" /> */}
              </Link>
              <br />
              <Link
                href="https://youtube.com/live/GKJP1qDq2es"
                // isExternal
                style={{ color: "blue" }}
              >
                {language === "en"
                  ? `Brother Philip Jong - Message 2`
                  : `鍾理恩弟兄信息 - 線上視頻鏈接-2`}
                {`  `}
                {/* <ExternalLinkIcon mx="2px" /> */}
              </Link>
              <br />
              <Link
                href="https://youtube.com/live/eovMEwud5Pk"
                // isExternal
                style={{ color: "blue" }}
              >
                {language === "en"
                  ? `Brother Philip Jong - Message 3`
                  : `鍾理恩弟兄信息 - 線上視頻鏈接-3`}
                {`  `}
                {/* <ExternalLinkIcon mx="2px" /> */}
              </Link>
            </Text>
          </>
        )}

        {/* Below is the placeholder for the cost information */}
        <Text as="b" w={[300, 400, 500, 900, 1500]}>
          {language === "en"
            ? `The cost for the conference will be paid for by the voluntary love offerings of the saints. For reference purpose, the conference charges per person are listed as follows:`
            : `特會的費用將由聖徒自願的愛心奉獻支付。以下所列個人的全程费用僅供參考:`}
        </Text>
        <TableContainer marginLeft={10} mt="-5" w={[300, 400, 500]}>
          <Table variant="simple" size="sm">
            <Thead style={{ fontWeight: "bold" }}>
              <Tr bg="lightGrey" borderBottom="3px solid black">
                <Th width={220}>{language === "en" ? `Age` : `年龄`}</Th>
                <Th margin={0} padding={0} border={0}>
                  {language === "en" ? `Cost` : `費用`}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td width={220}>
                  {language === "en"
                    ? `13 Years and Above`
                    : `13歲以上及成年人`}
                </Td>
                <Td margin={0} padding={0} border={0}>
                  $260
                </Td>
              </Tr>
              <Tr>
                <Td>{language === "en" ? `6 - 12 Years` : `6 - 12歲`}</Td>
                <Td margin={0} padding={0} border={0}>
                  $180
                </Td>
              </Tr>
              <Tr>
                <Td>{language === "en" ? `3 - 5 Years` : `3 - 5 歲`}</Td>
                <Td margin={0} padding={0} border={0}>
                  $65
                </Td>
              </Tr>
              <Tr>
                <Td>{language === "en" ? `0 - 2 Years` : `0 - 2 歲`}</Td>
                <Td padding={0}>{language === "en" ? `Free` : `免費`}</Td>
              </Tr>
              <Tr>
                <Td>{language === "en" ? `Commuter` : `通勤`}</Td>
                <Td padding={0}>
                  $82{" "}
                  {language === "en" ? `(Meals Are Not Included)` : `(不含餐)`}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  {language === "en"
                    ? `Single-bedroom Suite (Bathroom Shared)`
                    : `單人房(二房共用浴室)`}
                </Td>
                <Td padding={0}>
                  {language === "en" ? `Add $25` : `另加 $25`}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  {language === "en"
                    ? `Other Conference Cost`
                    : `特會其他的費用`}
                </Td>
                <Td padding={0}>
                  {language === "en" ? `Add $25` : `另加 $25`}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <Text as="b">
          {language === "en" ? `Airport Transportation:` : `機場接送：`}
        </Text>
        <Text mt="-5" w={[300, 400, 500, 900, 1500]}>
          {language === "en"
            ? `Airport pickup and dropoff will be limited to New Jersey Liberty International airport (EWR).
            They will also be limited to the following dates and times:
            For pickup on 7/4, flights arrive between 10:00 AM and 2:00 PM.
            For dropoff on 7/7, flights depart after 5:00 PM.
            Please do not book your airline tickets before receiving conference registration confirmation email.
            `
            : `僅限於新澤西州紐瓦克國際機場 (EWR), 並且接送的日期和時間限定如下: 
            7 月 4 日，航班抵達在上午 10:00 點至下午 2:00 點之間。
            7 月 7 日，航班起飛在下午 5:00 點之後。
            沒有收到特會註冊確認電子郵件之前，請勿預訂機票。
            `}
        </Text>
        <Text as="b" w={[300, 400, 500, 900, 1500]}>
          {language === "en" ? `Contact Us:` : `若有疑間, 請与我們聯絡:`}
        </Text>
        <Text mt={-7} w={[300, 400, 500, 900, 1500]}>
          {language === "en"
            ? `eastcoastchristianconference@gmail.com`
            : `eastcoastchristianconference@gmail.com`}
        </Text>
      </Stack>
    </Box>
  );
}
