import React from "react";
import api from "../utils/axios";
import { saveAs } from "file-saver";
import {
  pdf,
  Font,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  useDisclosure,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text as ChakraText,
} from "@chakra-ui/react";
import mediumFontSrc from "../fonts/NotoSansSC-Medium.otf";
import regularFontSrc from "../fonts/NotoSansSC-Regular.otf";
import { prettyPrintDorm } from "../utils/PrettyPrints";

// Default font does not correctly display chinese characters
Font.register({ family: "NotoSansSC-Medium", src: mediumFontSrc });
Font.register({ family: "NotoSansSC-Regular", src: regularFontSrc });

// PDFs generated with 72 dpi by default
const dpi = 72;
const widthInches = 4;
const heightInches = 3;

const cardBorderWidth = 4;
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 72,
  },
  cardColumn: {
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    borderWidth: cardBorderWidth,
    borderColor: "black",
    borderStyle: "solid",
    borderRadius: 5,
    height: dpi * heightInches - cardBorderWidth / 2,
    width: dpi * widthInches - cardBorderWidth / 2,
    marginTop: 20,
  },
  name: {
    fontSize: 24,
    textAlign: "center",
    color: "black",
    backgroundColor: "white",
    // color: "white",
    // backgroundColor: "darkred",
    borderBottomWidth: 5,
    borderStyle: "solid",
    borderColor: "black",
    borderRadius: 0,
    fontFamily: "NotoSansSC-Medium",
    paddingVertical: 10,
  },
  chineseName: {
    fontSize: 24,
    textAlign: "center",
    color: "white",
    backgroundColor: "darkred",
    fontFamily: "NotoSansSC-Medium",
    paddingVertical: 5,
  },
  subtitle: {
    fontSize: 12,
    marginTop: 5,
    fontFamily: "NotoSansSC-Regular",
    justifySelf: "flex-end",
  },
});

function prettyPrintFamilyId(user) {
  if (!user?.familyId) {
    return "";
  }

  return `Family ID: ${user.familyId}`;
}

function prettyPrintChurch(user) {
  if (!user?.church) {
    return "Church: None";
  }

  return `Church: ${user.church?.name} - ${user.church?.long_name}`;
}

function prettyPrintGroup(user) {
  if (!user?.group) {
    return "Group: None";
  }

  return `Group: ${user.group?.name}`;
}

function prettyPrintLeader(user) {
  if (!user?.leader) {
    return "";
  }

  return "Group Leader";
}

function PrintableUserLabelsDocument({ userPages, eventDesc }) {
  function createUserCell(user) {
    if (!user) {
      return null;
    }

    return (
      <View style={styles.cardContainer}>
        <>
          <div style={styles.name}>
            {user.firstName || user.lastName ? (
              <Text>{`${user.firstName} ${user.lastName}`}</Text>
            ) : null}
            {user.chineseName ? <Text>{user.chineseName}</Text> : null}
          </div>
          {eventDesc ? <Text style={styles.subtitle}>{eventDesc}</Text> : null}
          <Text style={styles.subtitle}>{prettyPrintFamilyId(user)}</Text>
          <Text style={styles.subtitle}>{prettyPrintChurch(user)}</Text>
          <Text style={styles.subtitle}>{prettyPrintGroup(user)}</Text>
          <Text style={styles.subtitle}>{prettyPrintDorm(user)}</Text>
          <Text style={styles.subtitle}>{prettyPrintLeader(user)}</Text>
        </>
      </View>
    );
  }

  return (
    <Document>
      {userPages &&
        userPages.map(({ key, page }) => (
          <Page key={key}>
            <View style={styles.container}>
              <View style={styles.cardColumn}>
                {createUserCell(page[0])}
                {createUserCell(page[2])}
                {createUserCell(page[4])}
              </View>
              <View style={styles.cardColumn}>
                {createUserCell(page[1])}
                {createUserCell(page[3])}
                {createUserCell(page[5])}
              </View>
            </View>
          </Page>
        ))}
    </Document>
  );
}

function PrintableUserLabelsDocumentButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userFilter, setUserFilter] = React.useState("");

  async function handleDownload(isFiltering) {
    let users = (await api.get("/api/users/csvAttending")).data;

    if (isFiltering) {
      let familyIdMap = {};
      const familyIds = userFilter.toLowerCase().split(/,(?![^()]*\))/);
      for (const familyId of familyIds) {
        if (familyId.includes("(") && familyId.includes(")")) {
          const [key, values] = familyId.split("(");
          const cleanedValues = values
            .replace(")", "")
            .split(",")
            .map((v) => v.trim());
          familyIdMap[key.trim()] = cleanedValues;
        } else {
          familyIdMap[familyId.trim()] = null;
        }
      }

      const filteredUsers = [];
      for (const user of users) {
        const userFamilyId = user.familyId?.toString();
        if (userFamilyId in familyIdMap) {
          if (familyIdMap[userFamilyId] == null) {
            filteredUsers.push(user);
          } else {
            if (
              familyIdMap[userFamilyId].includes(
                user.firstName?.toLowerCase()
              ) ||
              familyIdMap[userFamilyId].includes(
                user.lastName?.toLowerCase()
              ) ||
              familyIdMap[userFamilyId].includes(user.chineseName)
            ) {
              filteredUsers.push(user);
            }
          }
        }
      }

      users = filteredUsers;
    }

    if (users.length == 0) {
      alert("No users found.");
      return;
    }

    const pages = [];

    for (let i = 0; i < users.length; i += 6) {
      pages.push({ key: i, page: users.slice(i, i + 6) });
    }

    let eventDesc = "";
    await api.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        const eventName = res.data[0].eventName;
        const chineseEventName = res.data[0].eventName2;
        const eventStartYear = new Date(
          res.data[0].eventStartDate
        ).getFullYear();
        const eventEndYear = new Date(res.data[0].eventEndDate).getFullYear();
        const eventYear =
          eventStartYear == eventEndYear
            ? eventStartYear
            : eventStartYear + "-" + eventEndYear;

        eventDesc = `${eventYear} ${eventName} ${chineseEventName}`;
      }
    });

    // This loads the pdf after the user has clicked on the button rather than loading when the page renders
    const blob = await pdf(
      <PrintableUserLabelsDocument userPages={pages} eventDesc={eventDesc} />
    ).toBlob();
    saveAs(blob, "users_printable_labels.pdf");
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Printable User Labels</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ChakraText>
              This will generate a PDF of user labels for all attending users.
              If you want to print only a specific group of users, enter a comma
              separated list of family IDs. For each family id, specify in
              parentheses a comma separated list of user names to print only
              those users in the family.
            </ChakraText>
            <Input
              type="text"
              value={userFilter}
              onChange={(e) => setUserFilter(e.target.value)}
              marginTop={3}
              placeholder="Ex. 101,102,103(John, Jane),104"
            />
          </ModalBody>
          <ModalFooter>
            <>
              <Button
                variant="ghost"
                colorScheme="teal"
                margin={0}
                onClick={() => handleDownload(false)}
              >
                Print all users
              </Button>

              <Button
                variant="ghost"
                margin={3}
                onClick={() => handleDownload(true)}
              >
                Print filtered users
              </Button>
            </>
            <Button colorScheme="blue" margin={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button onClick={onOpen} margin={2} marginBottom={10}>
        Download Printable User Labels
      </Button>
    </>
  );
}

export default PrintableUserLabelsDocumentButton;
