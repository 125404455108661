import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Heading } from "@chakra-ui/react";
import { UserContext } from "../../context/UserContext";
import RegistrationForm from "../../components/registration-form/RegistrationForm";

function RegistrationDetail() {
  const { language } = useContext(UserContext);
  const { id } = useParams();

  return (
    <div>
      <Heading textAlign="center" margin="20px">
        {language === "en"
          ? "Registration Detail"
          : "登记註册 詳資"}
      </Heading>
      {id ? (
        <Heading as="h4" size="md" textAlign="center">
          {language === "en"
            ? "Update Family Member"
            : "變更家庭成员資料"}
        </Heading>
      ) : (
        <Heading as="h4" size="md" textAlign="center">
          {language === "en" ? "Add Family Member" : "新家庭成员登記"}
        </Heading>
      )}
      <RegistrationForm />
    </div>
  );
}

export default RegistrationDetail;
