import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Select,
  HStack,
  UnorderedList,
  ListItem,
  Input,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import { Link as ReachLink } from "react-router-dom";
import api from "../../../utils/axios";

function AdminStatisticsDorms() {
  const { language } = useContext(UserContext);
  const [roomNumberFilter, setRoomNumberFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("All");
  const [occupancyFilter, setOccupancyFilter] = useState("All");
  const [dorms, setDorms] = useState([]);
  const [filteredDorms, setFilteredDorms] = useState([]);
  const [churches, setChurches] = useState([]);

  function prettyPrintBuilding(building) {
    if (building == null) {
      return "";
    }

    if (building.building_type) {
      return `${building.name} (${building.building_type})`;
    }

    return building.name;
  }

  function prettyPrintChurch(id) {
    if (!id) {
      return "";
    }

    return churches.find((church) => church._id === id)?.long_name;
  }

  function prettyPrintUser(user) {
    if (!user) {
      return "";
    }

    let label = user.firstName;

    if (user.lastName) {
      label += ` ${user.lastName}`;
    }

    if (user.chineseName) {
      label += ` ${user.chineseName}`;
    }

    if (user.familyId) {
      label += ` (Family ID: ${user.familyId})`;
    }

    if (user.gender) {
      label += `, ${user.gender}`;
    }

    if (user.grade) {
      label += `, ${user.grade}`;
    }

    if (user.church) {
      label += `, ${prettyPrintChurch(user.church, churches)}`;
    }

    return label;
  }

  useEffect(() => {
    const updatedDorms = dorms
      .filter((dorm) => {
        if (roomNumberFilter === "") {
          return true;
        } else {
          return dorm.room_number?.toString().includes(roomNumberFilter);
        }
      })
      .filter((dorm) => {
        if (occupancyFilter === "All") {
          return true;
        } else if (occupancyFilter === "Empty") {
          return dorm.curr_occupancy === 0;
        } else if (occupancyFilter === "Partially Occupied") {
          return (
            dorm.curr_occupancy > 0 && dorm.curr_occupancy < dorm.max_occupancy
          );
        } else if (occupancyFilter === "Full") {
          return dorm.curr_occupancy === dorm.max_occupancy;
        }
      })
      .filter((dorm) => {
        if (genderFilter === "All") {
          return true;
        } else {
          return dorm.users.every((user) => user.gender === genderFilter);
        }
      });
    setFilteredDorms(updatedDorms);
  }, [roomNumberFilter, genderFilter, occupancyFilter]);

  useEffect(() => {
    api.get("/api/dorms").then((res) => {
      setDorms(res.data);
      setFilteredDorms(res.data);
    });
    api.get("/api/churches").then((res) => {
      setChurches(res.data);
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Dorm Statistics" : "[Dorm 統計數據]"}
      </Heading>
      <HStack>
        <Text as="b">Room Number:</Text>
        <Input
          type="text"
          width={200}
          value={roomNumberFilter}
          onChange={(e) => {
            setRoomNumberFilter(e.target.value);
          }}
        />
        <Text as="b">Gender:</Text>
        <Select
          width={200}
          value={genderFilter}
          onChange={(e) => {
            setGenderFilter(e.target.value);
          }}
        >
          <option value="All">All</option>
          <option value="Male">Male Only</option>
          <option value="Female">Female Only</option>
        </Select>
        <Text as="b">Occupancy:</Text>
        <Select
          width={200}
          value={occupancyFilter}
          onChange={(e) => {
            setOccupancyFilter(e.target.value);
          }}
        >
          <option value="All">All</option>
          <option value="Empty">Empty</option>
          <option value="Partially Occupied">Partially Occupied</option>
          <option value="Full">Full</option>
        </Select>
      </HStack>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Room Number</Th>
              <Th>Name</Th>
              <Th>Floor</Th>
              <Th>Occupancy</Th>
              <Th>Description</Th>
              <Th>Building</Th>
              <Th>Users</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredDorms &&
              filteredDorms.map((dorm) => (
                <Tr key={dorm._id}>
                  <Td>{dorm.room_number}</Td>
                  <Td>{dorm.name}</Td>
                  <Td>{dorm.floor}</Td>
                  <Td>{`${dorm.curr_occupancy}/${dorm.max_occupancy}`}</Td>
                  <Td>{dorm.description}</Td>
                  <Td>{prettyPrintBuilding(dorm.building)}</Td>
                  <Td>
                    <UnorderedList>
                      {dorm.users.map((user) => (
                        <ListItem key={user._id}>
                          {prettyPrintUser(user)}
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Button as={ReachLink} to="/admin/statistics" margin="0px 20px">
        Back
      </Button>
    </Box>
  );
}

export default AdminStatisticsDorms;
