import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Select,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  TableContainer,
  HStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import AdminUserFilter from "../../../components/AdminUserFilter";
import AdminUserTableFooter from "../../../components/AdminUserTableFooter";
import config from "../../../config.json";
import { hasPermission } from "../../../utils/helpers";

function AdminAssignGroups() {
  const { user, language } = useContext(UserContext);

  const [filteredUsers, setFilteredUsers] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [refreshToggle, setRefreshToggle] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumberChange, setPageNumberChange] = useState(false);

  const [groups, setGroups] = useState([]);

  const [updateNewGroup, setUpdateNewGroup] = useState(null);
  const [updateOldGroup, setUpdateOldGroup] = useState(null);

  const [updateUserId, setUpdateUserId] = useState(null);

  function fetchGroupData() {
    api.get("/api/groups").then((res) => {
      setGroups(res.data);
    });
  }

  useEffect(() => {
    fetchGroupData();
  }, []);

  function getAge(birthdate) {
    if (birthdate == null) {
      return false;
    }

    const mdrDate = new Date(config.MDR_START_DATE);
    let calculatedAge = mdrDate.getFullYear() - birthdate.getFullYear();
    const monthDiff = mdrDate.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && mdrDate.getDate() < birthdate.getDate())
    ) {
      calculatedAge -= 1;
    }

    return calculatedAge;
  }

  function populateUserTableFields(u) {
    return {
      ...u,
      groupName: u.group != null ? u.group?.name : "",
      groupLanguage: u.group != null ? u.group?.language : "",
      age: u.birthdate != null ? getAge(new Date(u.birthdate)) : -1,
    };
  }

  function renderGroupName(g) {
    const groupIdPrefix = g.group_id ? `${g.group_id}: ` : "";
    const groupDescriptionSuffix = g.description ? ` (${g.description})` : "";
    // const groupLanguageSuffix = g.language ? ` (${g.language})` : "";

    return (
      `(${g.curr_occupancy}/${g.max_occupancy}) ` +
      groupIdPrefix +
      g.name +
      groupDescriptionSuffix
    );
  }

  async function handleUpdate(u) {
    if (!hasPermission(user, ["assign_groups", "assign_groups_for_church"])) {
      alert("You do not have permission to update groups");
      return;
    }

    let userObj = u;

    userObj = {
      ...u,
      group: updateNewGroup || u.group,
    };

    if (updateNewGroup && updateNewGroup !== "None") {
      const newGroup = groups.find((g) => g._id === updateNewGroup);
      if (newGroup.curr_occupancy >= newGroup.max_occupancy) {
        alert("Group is full");
        setUpdateNewGroup(updateOldGroup);
        return;
      }
    }

    if (updateNewGroup === "None") {
      delete userObj.group;
    }

    try {
      const updatedUser = (
        await api.put(
          `/api/users/admin-table-format/assign-groups/${u._id}`,
          userObj
        )
      ).data;

      const updatedUsers = filteredUsers.map((oldUser) => {
        if (oldUser._id === u._id) {
          return populateUserTableFields(updatedUser);
        }
        return oldUser;
      });

      setFilteredUsers(updatedUsers);

      if (updateNewGroup != null && updateNewGroup !== updateOldGroup) {
        groups.forEach((g) => {
          if (g._id === updatedUser.group?._id) {
            g.curr_occupancy += 1;
          }
          if (g._id === updateOldGroup) {
            g.curr_occupancy -= 1;
          }
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setUpdateUserId(null);
    setUpdateOldGroup(null);
    setUpdateNewGroup(null);
  }

  function prettyPrintLeaderLastName(user) {
    if (!user) {
      return "";
    }

    if (!user?.leader) {
      return user.lastName;
    }

    return user.lastName + "**";
  }

  async function randomlyAssignGroups(
    overrideCurrentGroups = false,
    groupLanguage = "Chinese"
  ) {
    if (!hasPermission(user, ["assign_groups"])) {
      alert("You do not have permission to assign groups.");
      return;
    }

    if (
      window.confirm(
        overrideCurrentGroups
          ? `Are you sure you want to randomly assign ${groupLanguage} groups? This will override any current group assignments.`
          : `Are you sure you want to randomly assign ${groupLanguage} groups? This will keep any current group assignments.`
      )
    ) {
      try {
        await api.post("/api/users/randomly-assign-groups", {
          overrideCurrentGroups,
          groupLanguage,
        });
        fetchGroupData();
        setRefreshToggle(!refreshToggle);
      } catch (err) {
        alert(
          `Error: ${err?.response?.data?.message}` ||
            "An unexpected error occurred."
        );
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Assign Users to Groups" : "将用户分配到群组"}
      </Heading>
      <AdminUserFilter
        setFilteredUsers={setFilteredUsers}
        setPageLoading={setPageLoading}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageTotal={setPageTotal}
        pageNumberChange={pageNumberChange}
        onlyAttendingAndAccepted
        refreshToggle={refreshToggle}
      />
      {pageLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <TableContainer margin={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th scope="col">First Name</Th>
                  <Th scope="col">Last Name</Th>
                  <Th scope="col">Chin. Name</Th>
                  <Th scope="col">Fam. Id</Th>
                  <Th scope="col">Age</Th>
                  <Th scope="col">Grade</Th>
                  <Th scope="col">Gender</Th>
                  <Th scope="col">Commuting</Th>
                  <Th scope="col">Preferred Lang.</Th>
                  <Th scope="col">Spc. Needs</Th>
                  <Th scope="col">Reg. Status</Th>
                  <Th scope="col">Group</Th>
                  <Th scope="col"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers &&
                  filteredUsers.map((u) => (
                    <Tr
                      key={u._id}
                      backgroundColor={
                        u._id != updateUserId
                          ? ""
                          : updateNewGroup === updateOldGroup
                          ? ""
                          : "lightblue"
                      }
                    >
                      <Td>{u.firstName}</Td>
                      <Td>
                        {u.leader ? (
                          <Tooltip label={"Group Leader"}>
                            {prettyPrintLeaderLastName(u)}
                          </Tooltip>
                        ) : (
                          prettyPrintLeaderLastName(u)
                        )}
                      </Td>
                      <Td>{u.chineseName}</Td>
                      <Td>{u.familyId}</Td>
                      <Td>{u.age >= 0 ? u.age : ""}</Td>
                      <Td>{u.grade}</Td>
                      <Td>{u.gender}</Td>
                      <Td>
                        {u.resident_commuter_status?.includes("Commuting")
                          ? "Yes"
                          : "No"}
                      </Td>
                      <Td>{u.preferredLanguage}</Td>
                      <Td>{u.hasSpecialNeeds ? "Yes" : "No"}</Td>
                      <Td>{u.registrationStatus}</Td>
                      <Td>
                        <Select
                          value={
                            u._id === updateUserId
                              ? updateNewGroup || u.group?._id
                              : u.group?._id || "None"
                          }
                          onChange={async (e) => {
                            setUpdateNewGroup(e.target.value);
                            setUpdateOldGroup(u.group?._id);
                            setUpdateUserId(u._id);
                          }}
                        >
                          <option value="None">None</option>
                          {groups &&
                            groups.map((g) => (
                              <option value={g._id} key={g._id}>
                                {renderGroupName(g)}
                              </option>
                            ))}
                        </Select>
                      </Td>
                      <Td>
                        <Button
                          colorScheme={"green"}
                          isDisabled={
                            !hasPermission(user, [
                              "assign_groups",
                              "assign_groups_for_church",
                            ]) || u._id != updateUserId
                              ? true
                              : updateNewGroup === updateOldGroup
                              ? true
                              : false
                          }
                          onClick={() => handleUpdate(u)}
                        >
                          Update
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <AdminUserTableFooter
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageTotal={pageTotal}
            pageNumberChange={pageNumberChange}
            setPageNumberChange={setPageNumberChange}
          />
          <HStack spacing={5} margin={5}>
            <Button
              colorScheme="red"
              onClick={() => randomlyAssignGroups(true)}
              isDisabled={!hasPermission(user, ["assign_groups"])}
            >
              Randomly Assign Chinese Groups - Override Current Assignments
            </Button>
            <Button
              colorScheme="red"
              onClick={() => randomlyAssignGroups(false)}
              isDisabled={!hasPermission(user, ["assign_groups"])}
            >
              Randomly Assign Chinese Groups - Keep Current Assignments
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
}

export default AdminAssignGroups;
