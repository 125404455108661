import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminAddEditGroup() {
  const { user, language } = useContext(UserContext);
  const { groupId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [classrooms, setClassrooms] = useState([]);
  const [leaders, setLeaders] = useState([]);

  const [groupIdField, setGroupIdField] = useState("");
  const [name, setName] = useState("");
  const [groupLanguage, setGroupLanguage] = useState("");
  const [description, setDescription] = useState("");
  const [maxOccupancy, setMaxOccupancy] = useState("");
  const [classroom, setClassroom] = useState(null);
  const [leader, setLeader] = useState(null);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/groups/${groupId}`).then((res) => {
        setGroupIdField(res.data.group_id?.toString() || "");
        setName(res.data.name || "");
        setGroupLanguage(res.data.language || "");
        setDescription(res.data.description || "");
        setMaxOccupancy(res.data.max_occupancy?.toString() || "");
        setClassroom(res.data.classroom?._id);
        setLeader(res.data.leader?._id);
      });
    }
    api.get("/api/classrooms").then((res) => {
      setClassrooms(res.data);
    });
    api.get("/api/users").then((res) => {
      setLeaders(res.data.filter((user) => user.leader === true));
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    const groupObj = {
      group_id: parseInt(groupIdField.trim(), 10),
      name: name.trim(),
      language: groupLanguage.trim(),
      description: description.trim(),
      max_occupancy: parseInt(maxOccupancy.trim(), 10) || 1,
      ...(classroom && { classroom }),
      ...(leader && { leader }),
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/groups`, groupObj);
      } else {
        await api.put(`/api/groups/${groupId}`, groupObj);
      }
      navigate("/admin/reference-data/groups");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  function getClassroomString(cObj) {
    return `Room ${cObj.classroom_number}, Floor ${cObj.floor}, ${cObj.building.name}`;
  }

  function getLeaderString(lObj) {
    return `${lObj.firstName} ${lObj.lastName} (${lObj.email})`;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Group" : "Edit Group"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Group Id:</FormLabel>
            <Input
              placeholder={language === "en" ? "Group Id" : "(Group Id)"}
              value={groupIdField}
              onChange={(e) => setGroupIdField(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Group Name:</FormLabel>
            <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Language:</FormLabel>
            <Input
              placeholder={language === "en" ? "Language" : "(Language)"}
              value={groupLanguage}
              onChange={(e) => setGroupLanguage(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description:</FormLabel>
            <Input
              placeholder={language === "en" ? "Description" : "(Description)"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Max Occupancy:</FormLabel>
            <Input
              placeholder={
                language === "en" ? "Max Occupancy" : "(Max Occupancy)"
              }
              value={maxOccupancy}
              onChange={(e) => setMaxOccupancy(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Classroom:</FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select Classroom" : "(Select Classroom)"
              }
              value={classroom ?? ""}
              onChange={(e) => setClassroom(e.target.value || null)}
            >
              {classrooms.length > 0 &&
                classrooms.map((c) => (
                  <option value={c._id} key={c._id}>
                    {getClassroomString(c)}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Leader:</FormLabel>
            <Select
              placeholder={
                language === "en" ? "Select Leader" : "(Select Leader)"
              }
              value={leader ?? ""}
              onChange={(e) => setLeader(e.target.value || null)}
            >
              {leaders.length > 0 &&
                leaders.map((l) => (
                  <option value={l._id} key={l._id}>
                    {getLeaderString(l)}
                  </option>
                ))}
            </Select>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/groups"
              margin="0px 20px"
            >
              Back
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!hasPermission(user, "edit_reference_data_groups")}
            >
              {language === "en" ? "Submit" : "(Submit)"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditGroup;
