export function prettyPrintDorm(user) {
  if (!user?.dorm) {
    return "Dorm: None";
  }

  return `Dorm: ${user.dorm?.building?.name} - Room ${
    user.dorm?.room_number || "N/A"
  }${user.dorm?.name ? " " + user.dorm?.name : ""}, Floor ${
    user.dorm?.floor || "N/A"
  }`;
}

export function prettyPrintChurchShort(church) {
  if (!church) {
    return "";
  }

  if (!church.tier || church.tier <= 0) {
    return church.name;
  }

  return church.name + "*".repeat(church.tier);
}

export function prettyPrintChurch(church) {
  if (!church) {
    return "";
  }

  return `${church.name} (${church.long_name})`;
}

export function prettyPrintBuilding(buildingId, buildings) {
  if (buildingId == null) {
    return "";
  }

  const bObj = buildings.find((b) => b._id === buildingId);
  if (bObj == null) {
    return "";
  }

  if (bObj.building_type) {
    return `${bObj.name} (${bObj.building_type})`;
  }

  return bObj.name;
}
