import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminManageRoles() {
  const { user, language } = useContext(UserContext);

  const [roles, setRoles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  function fetchReferenceData() {
    api.get("/api/roles").then((res) => {
      setRoles(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleDelete(d) {
    if (!hasPermission(user, "edit_reference_data_roles")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to delete roles.");
      return;
    }

    if (
      // eslint-disable-next-line no-alert
      window.confirm("Are you sure you wish to delete this role?")
    ) {
      try {
        await api.delete(`/api/roles/${d._id}`);
        fetchReferenceData();

        // eslint-disable-next-line no-alert
        alert("Role deleted");
        setErrorMessage("");
      } catch (err) {
        // eslint-disable-next-line no-console
        setErrorMessage(
          err?.response?.data?.message || "An unexpected error occurred."
        );
        console.log(err);
      }
    }
  }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Roles
      </Heading>
      {errorMessage && <Box color="red">{errorMessage}</Box>}
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Role Name</Th>
              <Th>Description</Th>
              <Th>Permissions</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {roles &&
              roles.map((role) => (
                <Tr key={role._id}>
                  <Td>{role?.name}</Td>
                  <Td>{role.description || ""}</Td>
                  <Td>
                    {
                      <ul>
                        {role.permissions.map((permission) => (
                          <li key={permission}>{permission}</li>
                        ))}
                      </ul>
                    }
                  </Td>
                  <Td>
                    <Button
                      as={ReachLink}
                      to={
                        hasPermission(user, "edit_reference_data_roles")
                          ? `/admin/reference-data/roles/${role._id}`
                          : "#"
                      }
                      size="md"
                      colorScheme="yellow"
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_roles")
                      }
                    >
                      {language === "en" ? "Update" : "[Update]"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(role)}
                      isDisabled={
                        !hasPermission(user, "edit_reference_data_roles")
                      }
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          Back
        </Button>
        <Button
          as={ReachLink}
          to={
            hasPermission(user, "edit_reference_data_roles")
              ? "/admin/reference-data/roles/add"
              : "#"
          }
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_roles")}
        >
          {language === "en" ? "Add Role" : "[Add Role]"}
        </Button>
      </Box>
    </VStack>
  );
}

export default AdminManageRoles;
