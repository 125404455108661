import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tooltip,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import AdminUserFilter from "../../../components/AdminUserFilter";
import AdminUserTableFooter from "../../../components/AdminUserTableFooter";
import config from "../../../config";
import { hasPermission } from "../../../utils/helpers";

function AdminManageRegistrationStatus() {
  const { user, language } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateScope, setUpdateScope] = useState("individual");

  const [filteredUsers, setFilteredUsers] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumberChange, setPageNumberChange] = useState(false);

  const [updateUser, setUpdateUser] = useState(null);
  const [updatedRegistrationStatus, setUpdatedRegistrationStatus] =
    useState(null);
  const [oldRegistrationStatus, setOldRegistrationStatus] = useState(null);

  useEffect(() => {
    api.get("/api/users/process-expired-registration-tokens");
  }, []);

  function prettyPrintUser(user) {
    if (!user) {
      return "";
    }

    let name = `${user.firstName} ${user.lastName}`;
    if (user.chineseName) {
      name += ` (${user.chineseName})`;
    }
    if (updateScope === "family") {
      name = `Family ${user.familyId}`;
    }

    const emailMessage =
      updatedRegistrationStatus === "Waiting List" ||
      updatedRegistrationStatus === "Pending Acceptance" ||
      updatedRegistrationStatus === "Accepted"
        ? `Do you want to send an email notifying the user?`
        : "This will not send an email to the user.";

    return `Registration status for ${name} will be changed from "${updateUser?.registrationStatus}" to "${updatedRegistrationStatus}". ${emailMessage}`;
  }

  function prettyPrintChurch(u) {
    if (!u.church) {
      return u.churchOther ? "Other" : "";
    }

    if (!u.church.tier || u.church.tier <= 0) {
      return u.church.name;
    }

    return u.church.name + "*".repeat(u.church.tier);
  }

  async function handleUpdate(sendEmail) {
    if (!updateUser || !updatedRegistrationStatus) {
      return;
    }

    let userObj = {
      registrationStatus: updatedRegistrationStatus,
    };

    try {
      if (updateScope === "individual") {
        const updatedUser = (
          await api.put(
            `/api/users/admin-table-format/${
              sendEmail ? "registration-status-email" : "registration-status"
            }/${updateUser._id}`,
            userObj
          )
        ).data;

        updateUser.registrationStatus = updatedUser.registrationStatus;
      } else if (updateScope === "family") {
        const updatedFamily = (
          await api.put(
            `/api/users/admin-table-format/${
              sendEmail
                ? "registration-status-email-family"
                : "registration-status-family"
            }/${updateUser.familyId}`,
            userObj
          )
        ).data;

        const updatedUsers = filteredUsers.map((oldUser) => {
          const updatedUser = updatedFamily.find((f) => f._id === oldUser._id);
          if (updatedUser) {
            return {
              ...oldUser,
              registrationStatus: updatedUser.registrationStatus,
            };
          }
          return oldUser;
        });

        setFilteredUsers(updatedUsers);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      onClose();
    }

    setUpdateUser(null);
    setOldRegistrationStatus(null);
    setUpdatedRegistrationStatus(null);
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Manage Registration Status" : "設定註册狀態"}
      </Heading>
      <AdminUserFilter
        setFilteredUsers={setFilteredUsers}
        setPageLoading={setPageLoading}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageTotal={setPageTotal}
        pageNumberChange={pageNumberChange}
        sortByRegisterTimestamp
      />
      {pageLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update Registration Status</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{prettyPrintUser(updateUser)}</ModalBody>
              <ModalFooter>
                {updatedRegistrationStatus === "Waiting List" ||
                updatedRegistrationStatus === "Pending Acceptance" ||
                updatedRegistrationStatus === "Accepted" ? (
                  <>
                    <Button
                      variant="ghost"
                      colorScheme="teal"
                      margin={0}
                      onClick={() => handleUpdate(true)}
                    >
                      Update and Send Email
                    </Button>

                    <Button
                      variant="ghost"
                      margin={3}
                      onClick={() => handleUpdate(false)}
                    >
                      Update Only
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="ghost"
                    colorScheme="teal"
                    margin={3}
                    onClick={() => handleUpdate(false)}
                  >
                    Update
                  </Button>
                )}
                <Button colorScheme="blue" margin={3} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <TableContainer margin={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th scope="col">First Name</Th>
                  <Th scope="col">Last Name</Th>
                  <Th scope="col">Chin. Name</Th>
                  <Th scope="col">Email</Th>
                  <Th scope="col">Fam. Id</Th>
                  <Th scope="col">Attending</Th>
                  <Th scope="col">Gender</Th>
                  <Th scope="col">Church</Th>
                  <Th scope="col">Age</Th>
                  <Th scope="col">Grade</Th>
                  <Th scope="col">Registr. Time</Th>
                  <Th scope="col">Registration Status</Th>
                  <Th scope="col"></Th>
                  <Th scope="col">For Registerer</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers &&
                  filteredUsers.map((u) => (
                    <Tr key={u._id}>
                      <Td>{u.firstName}</Td>
                      <Td>{u.lastName}</Td>
                      <Td>{u.chineseName}</Td>
                      <Td>{u.email}</Td>
                      <Td>{u.familyId}</Td>
                      <Td>
                        {u.registrationStatus === "Not Attending"
                          ? "No"
                          : "Yes"}
                      </Td>
                      <Td>{u.gender}</Td>
                      <Td>
                        <Tooltip
                          label={u.church?.long_name || u.churchOther || ""}
                        >
                          {prettyPrintChurch(u)}
                        </Tooltip>
                      </Td>
                      <Td>{u.age >= 0 ? u.age : ""}</Td>
                      <Td>{u.grade}</Td>
                      <Td>
                        {new Date(u.registerTimestamp).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </Td>
                      <Td>
                        <Select
                          value={
                            u === updateUser
                              ? updatedRegistrationStatus
                              : u.registrationStatus
                          }
                          onChange={(e) => {
                            if (u !== updateUser) {
                              setOldRegistrationStatus(u.registrationStatus);
                            }

                            setUpdateUser(u);
                            setUpdatedRegistrationStatus(e.target.value);
                          }}
                        >
                          {config.REGISTRATION_STATUSES.map((status) => (
                            <option value={status} key={status}>
                              {status}
                            </option>
                          ))}
                        </Select>
                      </Td>
                      <Td>
                        <Button
                          colorScheme="green"
                          isDisabled={
                            !hasPermission(
                              user,
                              "manage_registration_status"
                            ) ||
                            u !== updateUser ||
                            updatedRegistrationStatus === oldRegistrationStatus
                          }
                          onClick={() => {
                            setUpdateScope("individual");
                            onOpen();
                          }}
                        >
                          Update
                        </Button>
                      </Td>
                      <Td>
                        {u.registerer == true && (
                          <Button
                            colorScheme="teal"
                            isDisabled={
                              !hasPermission(
                                user,
                                "manage_registration_status"
                              ) ||
                              u !== updateUser ||
                              updatedRegistrationStatus ===
                                oldRegistrationStatus
                            }
                            onClick={() => {
                              setUpdateScope("family");
                              onOpen();
                            }}
                          >
                            Update Family
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <AdminUserTableFooter
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageTotal={pageTotal}
            pageNumberChange={pageNumberChange}
            setPageNumberChange={setPageNumberChange}
          />
        </>
      )}
    </Box>
  );
}

export default AdminManageRegistrationStatus;
