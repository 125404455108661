import React, { useContext, useEffect, useState } from "react";
import api from "../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Input,
  Select,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import AdminUserFilter from "../../../components/AdminUserFilter";
import AdminUserTableFooter from "../../../components/AdminUserTableFooter";
import config from "../../../config.json";
import { hasPermission } from "../../../utils/helpers";

function AdminCheckedInOut() {
  const { user, language } = useContext(UserContext);

  const [filteredUsers, setFilteredUsers] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [checkedInOutCount, setCheckedInOutCount] = useState({});

  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumberChange, setPageNumberChange] = useState(false);

  const [updateUserId, setUpdateUserId] = useState(null);
  const [updateNewCheckedIn, setUpdateNewCheckedIn] = useState(null);
  const [updateNewCheckedInTimestamp, setUpdateNewCheckedInTimestamp] =
    useState(null);
  const [updateNewCheckedOut, setUpdateNewCheckedOut] = useState(null);
  const [updateNewCheckedOutTimestamp, setUpdateNewCheckedOutTimestamp] =
    useState(null);
  const [checkInDropdown, setCheckInDropdown] = useState("orig");
  const [checkOutDropdown, setCheckOutDropdown] = useState("orig");

  useEffect(() => {
    updateCheckedInOutCounts();
  }, []);

  async function updateCheckedInOutCounts() {
    api.get("/api/users/checked-in-out-count").then((res) => {
      setCheckedInOutCount(res.data);
    });
  }

  function getAge(birthdate) {
    if (birthdate == null) {
      return false;
    }

    const mdrDate = new Date(config.MDR_START_DATE);
    let calculatedAge = mdrDate.getFullYear() - birthdate.getFullYear();
    const monthDiff = mdrDate.getMonth() - birthdate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && mdrDate.getDate() < birthdate.getDate())
    ) {
      calculatedAge -= 1;
    }

    return calculatedAge;
  }

  function populateUserTableFields(u) {
    return {
      ...u,
      groupName: u.group != null ? u.group?.name : "",
      groupLanguage: u.group != null ? u.group?.language : "",
      age: u.birthdate != null ? getAge(new Date(u.birthdate)) : -1,
    };
  }

  function renderCheckedInOutStatus(status, timestamp) {
    if (!status || !timestamp) {
      return "No";
    }

    return new Date(timestamp).toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    });
  }

  async function updateUser(u) {
    let userObj = u;

    userObj = {
      ...u,
      checkedIn: checkInDropdown === "orig" ? u.checkedIn : updateNewCheckedIn,
      checkedInTimestamp:
        checkInDropdown === "orig"
          ? u.checkedInTimestamp
          : updateNewCheckedInTimestamp,
      checkedOut:
        checkOutDropdown === "orig" ? u.checkedOut : updateNewCheckedOut,
      checkedOutTimestamp:
        checkOutDropdown === "orig"
          ? u.checkedOutTimestamp
          : updateNewCheckedOutTimestamp,
    };
    try {
      const updatedUser = (
        await api.put(
          `/api/users/admin-table-format/checked-in-out/${u._id}`,
          userObj
        )
      ).data;

      const updatedUsers = filteredUsers.map((oldUser) => {
        if (oldUser._id === u._id) {
          return populateUserTableFields(updatedUser);
        }
        return oldUser;
      });

      setFilteredUsers(updatedUsers);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setUpdateUserId(null);
    setUpdateNewCheckedIn(null);
    setUpdateNewCheckedInTimestamp(null);
    setUpdateNewCheckedOut(null);
    setUpdateNewCheckedOutTimestamp(null);
    setCheckInDropdown("orig");
    setCheckOutDropdown("orig");

    updateCheckedInOutCounts();
  }

  async function updateFamily(u) {
    let updateObj = {};

    if (checkInDropdown !== "orig") {
      updateObj.checkedIn = updateNewCheckedIn;
      updateObj.checkedInTimestamp = updateNewCheckedInTimestamp;
    }

    if (checkOutDropdown !== "orig") {
      updateObj.checkedOut = updateNewCheckedOut;
      updateObj.checkedOutTimestamp = updateNewCheckedOutTimestamp;
    }

    try {
      const updatedFamily = (
        await api.put(
          `/api/users/admin-table-format/checked-in-out-family/${u.familyId}`,
          updateObj
        )
      ).data;

      const updatedUsers = filteredUsers.map((oldUser) => {
        const updatedUser = updatedFamily.find((f) => f._id === oldUser._id);
        if (updatedUser) {
          return populateUserTableFields(updatedUser);
        }
        return oldUser;
      });

      setFilteredUsers(updatedUsers);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    setUpdateUserId(null);
    setUpdateNewCheckedIn(null);
    setUpdateNewCheckedInTimestamp(null);
    setUpdateNewCheckedOut(null);
    setUpdateNewCheckedOutTimestamp(null);
    setCheckInDropdown("orig");
    setCheckOutDropdown("orig");

    updateCheckedInOutCounts();
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Check Users In/Out" : "報到/離營"}
      </Heading>
      <Text as="b">
        {language === "en"
          ? `Checked In: ${
              checkedInOutCount.numCheckedIn != undefined
                ? checkedInOutCount.numCheckedIn
                : ""
            }`
          : `[Checked In]: ${
              checkedInOutCount.numCheckedIn != undefined
                ? checkedInOutCount.numCheckedIn
                : ""
            }`}
      </Text>
      <Text as="b">
        {language === "en"
          ? `Checked Out: ${
              checkedInOutCount.numCheckedOut != undefined
                ? checkedInOutCount.numCheckedOut
                : ""
            }`
          : `[Checked Out]: ${
              checkedInOutCount.numCheckedOut != undefined
                ? checkedInOutCount.numCheckedOut
                : ""
            }`}
      </Text>
      <Text as="b">
        {language === "en"
          ? `Total: ${
              checkedInOutCount.total != undefined
                ? checkedInOutCount.total
                : ""
            }`
          : `[Total]: ${
              checkedInOutCount.total != undefined
                ? checkedInOutCount.total
                : ""
            }`}
      </Text>
      <AdminUserFilter
        setFilteredUsers={setFilteredUsers}
        setPageLoading={setPageLoading}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageSize={pageSize}
        setPageTotal={setPageTotal}
        pageNumberChange={pageNumberChange}
        onlyAttendingAndAccepted
      />
      {pageLoading ? (
        <Spinner size="xl" />
      ) : (
        <>
          <TableContainer margin={5}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th scope="col">First Name</Th>
                  <Th scope="col">Last Name</Th>
                  <Th scope="col">Chin. Name</Th>
                  <Th scope="col">Fam. Id</Th>
                  <Th scope="col">Checked In</Th>
                  <Th scope="col">Set Checked In</Th>
                  <Th scope="col">Checked Out</Th>
                  <Th scope="col">Set Checked Out</Th>
                  <Th scope="col"></Th>
                  <Th scope="col"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers &&
                  filteredUsers.map((u) => (
                    <Tr key={u._id}>
                      <Td>{u.firstName}</Td>
                      <Td>{u.lastName}</Td>
                      <Td>{u.chineseName}</Td>
                      <Td>{u.familyId}</Td>
                      <Td>
                        {u._id === updateUserId && checkInDropdown !== "orig"
                          ? renderCheckedInOutStatus(
                              updateNewCheckedIn,
                              updateNewCheckedInTimestamp
                            )
                          : renderCheckedInOutStatus(
                              u.checkedIn,
                              u.checkedInTimestamp
                            )}
                      </Td>
                      <Td>
                        <>
                          <Select
                            value={
                              u._id === updateUserId ? checkInDropdown : "orig"
                            }
                            onChange={async (e) => {
                              if (updateUserId != u._id) {
                                setCheckInDropdown("orig");
                                setCheckOutDropdown("orig");
                                setUpdateNewCheckedIn(null);
                                setUpdateNewCheckedInTimestamp(null);
                                setUpdateNewCheckedOut(null);
                                setUpdateNewCheckedOutTimestamp(null);
                              }

                              if (e.target.value === "orig") {
                                setUpdateNewCheckedIn(u.checkedIn);
                                setUpdateNewCheckedInTimestamp(
                                  u.checkedInTimestamp
                                );
                                setCheckInDropdown("orig");
                              } else if (e.target.value === "no") {
                                setUpdateNewCheckedIn(false);
                                setUpdateNewCheckedInTimestamp(null);
                                setCheckInDropdown("no");
                              } else if (e.target.value === "curr") {
                                setUpdateNewCheckedIn(true);
                                setUpdateNewCheckedInTimestamp(new Date());
                                setCheckInDropdown("curr");
                              } else if (e.target.value === "custom") {
                                setUpdateNewCheckedIn(true);
                                setUpdateNewCheckedInTimestamp(new Date());
                                setCheckInDropdown("custom");
                              }
                              setUpdateUserId(u._id);
                            }}
                          >
                            <option value="orig">No Change</option>
                            <option value="no">Not Checked In</option>
                            <option value="curr">Current Time</option>
                            <option value="custom">Custom Time</option>
                          </Select>
                          {u._id === updateUserId &&
                            checkInDropdown === "custom" && (
                              <>
                                <Input
                                  size="md"
                                  type="datetime-local"
                                  onChange={(e) => {
                                    if (
                                      Number.isNaN(Date.parse(e.target.value))
                                    ) {
                                      setUpdateNewCheckedIn(false);
                                      setUpdateNewCheckedInTimestamp(null);
                                    } else {
                                      setUpdateNewCheckedIn(true);
                                      setUpdateNewCheckedInTimestamp(
                                        new Date(e.target.value)
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                        </>
                      </Td>
                      <Td>
                        {u._id === updateUserId && checkOutDropdown !== "orig"
                          ? renderCheckedInOutStatus(
                              updateNewCheckedOut,
                              updateNewCheckedOutTimestamp
                            )
                          : renderCheckedInOutStatus(
                              u.checkedOut,
                              u.checkedOutTimestamp
                            )}
                      </Td>
                      <Td>
                        <>
                          <Select
                            value={
                              u._id === updateUserId ? checkOutDropdown : "orig"
                            }
                            onChange={async (e) => {
                              if (updateUserId != u._id) {
                                setCheckInDropdown("orig");
                                setCheckOutDropdown("orig");
                                setUpdateNewCheckedOut(null);
                                setUpdateNewCheckedOutTimestamp(null);
                                setUpdateNewCheckedOut(null);
                                setUpdateNewCheckedOutTimestamp(null);
                              }

                              if (e.target.value === "orig") {
                                setUpdateNewCheckedOut(u.checkedOut);
                                setUpdateNewCheckedOutTimestamp(
                                  u.checkedOutTimestamp
                                );
                                setCheckOutDropdown("orig");
                              } else if (e.target.value === "no") {
                                setUpdateNewCheckedOut(false);
                                setUpdateNewCheckedOutTimestamp(null);
                                setCheckOutDropdown("no");
                              } else if (e.target.value === "curr") {
                                setUpdateNewCheckedOut(true);
                                setUpdateNewCheckedOutTimestamp(new Date());
                                setCheckOutDropdown("curr");
                              } else if (e.target.value === "custom") {
                                setUpdateNewCheckedOut(true);
                                setUpdateNewCheckedOutTimestamp(new Date());
                                setCheckOutDropdown("custom");
                              }
                              setUpdateUserId(u._id);
                            }}
                          >
                            <option value="orig">No Change</option>
                            <option value="no">Not Checked Out</option>
                            <option value="curr">Current Time</option>
                            <option value="custom">Custom Time</option>
                          </Select>
                          {u._id === updateUserId &&
                            checkOutDropdown === "custom" && (
                              <>
                                <Input
                                  size="md"
                                  type="datetime-local"
                                  onChange={(e) => {
                                    if (
                                      Number.isNaN(Date.parse(e.target.value))
                                    ) {
                                      setUpdateNewCheckedOut(false);
                                      setUpdateNewCheckedOutTimestamp(null);
                                    } else {
                                      setUpdateNewCheckedOut(true);
                                      setUpdateNewCheckedOutTimestamp(
                                        new Date(e.target.value)
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                        </>
                      </Td>
                      <Td>
                        <Button
                          colorScheme={"green"}
                          isDisabled={
                            !(
                              hasPermission(
                                user,
                                "manage_registration_status"
                              ) &&
                              u._id === updateUserId &&
                              ((checkInDropdown !== "orig" &&
                                !(checkInDropdown === "no" && !u.checkedIn)) ||
                                (checkOutDropdown !== "orig" &&
                                  !(
                                    checkOutDropdown === "no" && !u.checkedOut
                                  )))
                            )
                          }
                          onClick={() => updateUser(u)}
                        >
                          Update
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          colorScheme={"teal"}
                          isDisabled={
                            !(
                              hasPermission(
                                user,
                                "manage_registration_status"
                              ) &&
                              u._id === updateUserId &&
                              ((checkInDropdown !== "orig" &&
                                !(checkInDropdown === "no" && !u.checkedIn)) ||
                                (checkOutDropdown !== "orig" &&
                                  !(
                                    checkOutDropdown === "no" && !u.checkedOut
                                  )))
                            )
                          }
                          onClick={() => updateFamily(u)}
                        >
                          Update Family
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <AdminUserTableFooter
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageTotal={pageTotal}
            pageNumberChange={pageNumberChange}
            setPageNumberChange={setPageNumberChange}
          />
        </>
      )}
    </Box>
  );
}

export default AdminCheckedInOut;
