import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import moment from "moment-timezone";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminAddEditEvent() {
  const { user, language } = useContext(UserContext);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [eventName, setEventName] = useState("");
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/events/${eventId}`).then((res) => {
        setEventName(res.data.eventName || "");
        setEventStartDate(
          res.data.eventStartDate ? new Date(res.data.eventStartDate) : null
        );
        setEventEndDate(
          res.data.eventEndDate ? new Date(res.data.eventEndDate) : null
        );
        setIsOpen(res.data.isOpen || false);
      });
    }
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    if (!hasPermission(user, "edit_reference_data_events")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to edit events.");
      return;
    }

    const eventObj = {
      eventName,
      eventStartDate,
      eventEndDate,
      isOpen,
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/events`, eventObj);
      } else {
        await api.put(`/api/events/${eventId}`, eventObj);
      }
      navigate("/admin/reference-data/events");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Event" : "Edit Event"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>
              {language === "en" ? "Event Name" : "[Event Name]"}:
            </FormLabel>
            <Input
              type="text"
              placeholder={language === "en" ? "Event Name" : "[Event Name]"}
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              isDisabled={!hasPermission(user, "edit_reference_data_events")}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en"
                ? "Event Start Date (EST)"
                : "(Event Start Date)"}
            </FormLabel>
            <Input
              placeholder="Select Event Start Date"
              size="md"
              type="datetime-local"
              value={
                eventStartDate
                  ? moment(eventStartDate)
                      .tz("America/New_York")
                      .format()
                      .slice(0, 16)
                  : ""
              }
              onChange={(e) => {
                if (Number.isNaN(Date.parse(e.target.value))) {
                  setEventStartDate(null);
                } else {
                  setEventStartDate(new Date(e.target.value));
                }
              }}
              isDisabled={!hasPermission(user, "edit_reference_data_events")}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en" ? "Event End Date (EST)" : "(Event End Date)"}
            </FormLabel>
            <Input
              placeholder="Select Event End Date"
              size="md"
              type="datetime-local"
              value={
                eventEndDate
                  ? moment(eventEndDate)
                      .tz("America/New_York")
                      .format()
                      .slice(0, 16)
                  : ""
              }
              onChange={(e) => {
                if (Number.isNaN(Date.parse(e.target.value))) {
                  setEventEndDate(null);
                } else {
                  setEventEndDate(new Date(e.target.value));
                }
              }}
              isDisabled={!hasPermission(user, "edit_reference_data_events")}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              {language === "en" ? "Registration Open" : "(Registration Open)"}
            </FormLabel>
            <Select
              placeholder={
                language === "en"
                  ? "Select Registration Status"
                  : "(Select Registration Status)"
              }
              value={isOpen ? "true" : "false"}
              onChange={(e) => setIsOpen(e.target.value === "true")}
              isDisabled={!hasPermission(user, "edit_reference_data_events")}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/events"
              margin="0px 20px"
            >
              Back
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!hasPermission(user, "edit_reference_data_events")}
            >
              {language === "en" ? "Submit" : "(Submit)"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditEvent;
