import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  TableCaption,
  TableContainer,
  Text,
  Select,
  HStack,
} from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";
import { Link as ReachLink } from "react-router-dom";
import api from "../../../utils/axios";
import config from "../../../config";
import { prettyPrintChurchShort } from "../../../utils/PrettyPrints";

function AdminStatisticsGrades() {
  const { language } = useContext(UserContext);
  const [churchStatistics, setChurchStatistics] = useState([]);
  const [registrationStatus, setRegistrationStatus] = useState("Accepted");

  const [total0to2Years, setTotal0to2Years] = useState(0);
  const [total3to5Years, setTotal3to5Years] = useState(0);
  const [totalGrade1to3, setTotalGrade1to3] = useState(0);
  const [totalGrade4to5, setTotalGrade4to5] = useState(0);
  const [totalGrade6to8, setTotalGrade6to8] = useState(0);
  const [totalGrade9to12, setTotalGrade9to12] = useState(0);
  const [totalCollege, setTotalCollege] = useState(0);
  const [totalCareer, setTotalCareer] = useState(0);
  const [totalParentsAdults, setTotalParentsAdults] = useState(0);

  function fetchReferenceData(status) {
    api.get(`/api/churches/statistics-reg-status/${status}`).then((res) => {
      let churchStatistics = [];
      let tempTotal0to2Years = 0;
      let tempTotal3to5Years = 0;
      let tempTotalGrade1to3 = 0;
      let tempTotalGrade4to5 = 0;
      let tempTotalGrade6to8 = 0;
      let tempTotalGrade9to12 = 0;
      let tempTotalCollege = 0;
      let tempTotalCareer = 0;
      let tempTotalParentsAdults = 0;

      res.data.forEach((church) => {
        let total0to2Years = 0;
        let total3to5Years = 0;
        let totalGrade1to3 = 0;
        let totalGrade4to5 = 0;
        let totalGrade6to8 = 0;
        let totalGrade9to12 = 0;
        let totalCollege = 0;
        let totalCareer = 0;
        let totalParentsAdults = 0;

        church.users.forEach((user) => {
          if (user.grade === "0-2 Years Old") {
            total0to2Years++;
            tempTotal0to2Years++;
          } else if (user.grade === "3-5 Years Old") {
            total3to5Years++;
            tempTotal3to5Years++;
          } else if (
            user.grade === "Grade 1" ||
            user.grade === "Grade 2" ||
            user.grade === "Grade 3"
          ) {
            totalGrade1to3++;
            tempTotalGrade1to3++;
          } else if (user.grade === "Grade 4" || user.grade === "Grade 5") {
            totalGrade4to5++;
            tempTotalGrade4to5++;
          } else if (
            user.grade === "Grade 6" ||
            user.grade === "Grade 7" ||
            user.grade === "Grade 8"
          ) {
            totalGrade6to8++;
            tempTotalGrade6to8++;
          } else if (
            user.grade === "Grade 9" ||
            user.grade === "Grade 10" ||
            user.grade === "Grade 11" ||
            user.grade === "Grade 12"
          ) {
            totalGrade9to12++;
            tempTotalGrade9to12++;
          } else if (user.grade === "College") {
            totalCollege++;
            tempTotalCollege++;
          } else if (user.grade === "Career") {
            totalCareer++;
            tempTotalCareer++;
          } else if (user.grade === "Parent" || user.grade === "Adult") {
            totalParentsAdults++;
            tempTotalParentsAdults++;
          } else {
            console.log("Unknown grade:", user.grade);
          }
        });
        churchStatistics.push({
          long_name: `${church.long_name}`,
          name: `${church.name}`,
          tier: `${church.tier}`,
          total0to2Years,
          total3to5Years,
          totalGrade1to3,
          totalGrade4to5,
          totalGrade6to8,
          totalGrade9to12,
          totalCollege,
          totalCareer,
          totalParentsAdults,
          subTotal:
            total0to2Years +
            total3to5Years +
            totalGrade1to3 +
            totalGrade4to5 +
            totalGrade6to8 +
            totalGrade9to12 +
            totalCollege +
            totalCareer +
            totalParentsAdults,
        });
      });
      setChurchStatistics(churchStatistics);
      setTotal0to2Years(tempTotal0to2Years);
      setTotal3to5Years(tempTotal3to5Years);
      setTotalGrade1to3(tempTotalGrade1to3);
      setTotalGrade4to5(tempTotalGrade4to5);
      setTotalGrade6to8(tempTotalGrade6to8);
      setTotalGrade9to12(tempTotalGrade9to12);
      setTotalCollege(tempTotalCollege);
      setTotalCareer(tempTotalCareer);
      setTotalParentsAdults(tempTotalParentsAdults);
    });
  }

  useEffect(() => {
    fetchReferenceData("Accepted");
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Grade Statistics" : "[Grade 統計數據]"}
      </Heading>
      <HStack>
        <Text as="b">Registration Status:</Text>
        <Select
          value={registrationStatus}
          width={200}
          onChange={(e) => {
            fetchReferenceData(e.target.value);
            setRegistrationStatus(e.target.value);
          }}
        >
          <option value="All">All</option>
          {config.REGISTRATION_STATUSES.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      </HStack>
      <TableContainer margin={5}>
        <Table variant="simple" size="sm">
          <TableCaption>
            {`Retreat Member Registration Counts - Only showing attending users with registration status: ${registrationStatus}`}
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Church</Th>
              <Th>0-2 Years Old</Th>
              <Th>3-5 Years Old</Th>
              <Th>Grade 1-3</Th>
              <Th>Grade 4-5</Th>
              <Th>Grade 6-8</Th>
              <Th>Grade 9-12</Th>
              <Th>College</Th>
              <Th>Career</Th>
              <Th>Parents/Adults</Th>
              <Th>Subtotal</Th>
            </Tr>
          </Thead>
          <Tbody>
            {churchStatistics.map((church) => (
              <Tr key={church.long_name}>
                <Td>
                  <Tooltip label={church.long_name}>
                    {prettyPrintChurchShort(church)}
                  </Tooltip>
                </Td>
                <Td>{church.total0to2Years}</Td>
                <Td>{church.total3to5Years}</Td>
                <Td>{church.totalGrade1to3}</Td>
                <Td>{church.totalGrade4to5}</Td>
                <Td>{church.totalGrade6to8}</Td>
                <Td>{church.totalGrade9to12}</Td>
                <Td>{church.totalCollege}</Td>
                <Td>{church.totalCareer}</Td>
                <Td>{church.totalParentsAdults}</Td>
                <Td fontWeight="bold">{church.subTotal}</Td>
              </Tr>
            ))}
            <Tr>
              <Td fontWeight="bold">Total</Td>
              <Td fontWeight="bold">{total0to2Years}</Td>
              <Td fontWeight="bold">{total3to5Years}</Td>
              <Td fontWeight="bold">{totalGrade1to3}</Td>
              <Td fontWeight="bold">{totalGrade4to5}</Td>
              <Td fontWeight="bold">{totalGrade6to8}</Td>
              <Td fontWeight="bold">{totalGrade9to12}</Td>
              <Td fontWeight="bold">{totalCollege}</Td>
              <Td fontWeight="bold">{totalCareer}</Td>
              <Td fontWeight="bold">{totalParentsAdults}</Td>
              <Td fontWeight="bold">
                {total0to2Years +
                  total3to5Years +
                  totalGrade1to3 +
                  totalGrade4to5 +
                  totalGrade6to8 +
                  totalGrade9to12 +
                  totalCollege +
                  totalCareer +
                  totalParentsAdults}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Button as={ReachLink} to="/admin/statistics" margin="0px 20px">
        Back
      </Button>
    </Box>
  );
}

export default AdminStatisticsGrades;
