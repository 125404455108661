import axios from "axios";

// Create an Axios instance to use for API requests
const api = axios.create();

// Add a response interceptor to the Axios instance
api.interceptors.response.use(
  // Handle successful responses
  (response) => response,

  // Handle error responses
  (error) => {
    // Destructure the error response
    const { response } = error;

    // Check if the response status code is 401 or 403
    if (response && (response.status === 401 || response.status === 403)) {
      // Logout user and redirect to login page
      api.post("/api/auth/logout");
      localStorage.removeItem("userData");

      window.location.href = "/login";
    }

    // Pass the error along to the calling function
    return Promise.reject(error);
  }
);

export default api;
