import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { UserContextProvider } from "./context/UserContext";
import UserRoutes from "./utils/UserRoutes";
// import OpenRegistrationRoutes from "./utils/OpenRegistrationRoutes";

import HeaderNav from "./components/navbar/HeaderNav";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import RegistrationFamily from "./pages/registration/RegistrationFamily";
import RegistrationDetail from "./pages/registration/RegistrationDetail";
import AdminRegistrationHome from "./pages/admin/AdminRegistrationHome";
import AdminRegistrationDetail from "./pages/admin/AdminRegistrationDetail";
import AdminCheckedInOut from "./pages/admin/checked-in-out/AdminCheckedInOut";
import AdminManageRegistrationStatus from "./pages/admin/manage-registration-status/AdminManageRegistrationStatus";
import AdminReferenceData from "./pages/admin/reference-data/AdminReferenceData";
import AdminStatistics from "./pages/admin/statistics/AdminStatistics";
import AdminStatisticsResidentCommuter from "./pages/admin/statistics/AdminStatisticsResidentCommuter";
import AdminStatisticsGrades from "./pages/admin/statistics/AdminStatisticsGrades";
import AdminStatisticsDorms from "./pages/admin/statistics/AdminStatisticsDorms";
import AdminManageGroups from "./pages/admin/reference-data/groups/AdminManageGroups";
import AdminAddEditGroup from "./pages/admin/reference-data/groups/AdminAddEditGroup";
import AdminManageDorms from "./pages/admin/reference-data/dorms/AdminManageDorms";
import AdminAddEditDorm from "./pages/admin/reference-data/dorms/AdminAddEditDorm";
import AdminManageClassrooms from "./pages/admin/reference-data/classrooms/AdminManageClassrooms";
import AdminAddEditClassroom from "./pages/admin/reference-data/classrooms/AdminAddEditClassroom";
import AdminManageBuildings from "./pages/admin/reference-data/buildings/AdminManageBuildings";
import AdminAddEditBuilding from "./pages/admin/reference-data/buildings/AdminAddEditBuilding";
import AdminManageChurches from "./pages/admin/reference-data/churches/AdminManageChurches";
import AdminAddEditChurch from "./pages/admin/reference-data/churches/AdminAddEditChurch";
import AdminManageEvents from "./pages/admin/reference-data/events/AdminManageEvents";
import AdminAddEditEvent from "./pages/admin/reference-data/events/AdminAddEditEvent";
import AdminManageMeals from "./pages/admin/reference-data/meals/AdminManageMeals";
import AdminAddEditMeal from "./pages/admin/reference-data/meals/AdminAddEditMeal";
import AdminManageLodging from "./pages/admin/reference-data/lodging/AdminManageLodging";
import AdminAddEditLodging from "./pages/admin/reference-data/lodging/AdminAddEditLodging";
import AdminManageOptions from "./pages/admin/reference-data/options/AdminManageOptions";
import ForgotPassword from "./pages/password-reset/ForgotPassword";
import ResetPassword from "./pages/password-reset/ResetPassword";
import ConfirmAcceptance from "./pages/registration/ConfirmAcceptance";
import ChangePassword from "./pages/password-reset/ChangePassword";
import AdminAssignGroups from "./pages/admin/assign-users/AdminAssignGroups";
import AdminAssignDorms from "./pages/admin/assign-users/AdminAssignDorms";
import AdminAddEditRole from "./pages/admin/reference-data/roles/AdminAddEditRole";
import AdminManageRoles from "./pages/admin/reference-data/roles/AdminManageRoles";

function App() {
  const theme = extendTheme({
    styles: {
      global: {
        body: {
          bg: "cornsilk",
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <UserContextProvider>
        <Router>
          <HeaderNav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/confirm-acceptance/:token"
              element={<ConfirmAcceptance />}
            />
            <Route element={<UserRoutes notLoggedInOnly />}>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Route>
            <Route element={<UserRoutes userOnly />}>
              <Route path="/registration" element={<RegistrationFamily />} />
              <Route
                path="/registration/add"
                element={<RegistrationDetail />}
              />
              <Route
                path="/registration/:id"
                element={<RegistrationDetail />}
              />
            </Route>
            <Route element={<UserRoutes adminOnly />}>
              <Route
                path="/admin/registration"
                element={<AdminRegistrationHome />}
              />
              <Route
                path="/admin/registration/family/:familyId"
                element={<RegistrationFamily />}
              />
              <Route
                path="/admin/registration/family/:familyId/add"
                element={<AdminRegistrationDetail />}
              />
              <Route
                path="/admin/registration/add"
                element={<AdminRegistrationDetail />}
              />
              <Route
                path="/admin/registration/:id"
                element={<AdminRegistrationDetail />}
              />
              <Route
                path="/admin/assign-groups"
                element={<AdminAssignGroups />}
              />
              <Route
                path="/admin/assign-dorms"
                element={<AdminAssignDorms />}
              />
              <Route
                path="/admin/checked-in-out"
                element={<AdminCheckedInOut />}
              />
              <Route
                path="/admin/manage-registration-status"
                element={<AdminManageRegistrationStatus />}
              />
              <Route
                path="/admin/reference-data"
                element={<AdminReferenceData />}
              />
              <Route
                path="/admin/reference-data/groups"
                element={<AdminManageGroups />}
              />
              <Route
                path="/admin/reference-data/groups/add"
                element={<AdminAddEditGroup />}
              />
              <Route
                path="/admin/reference-data/groups/:groupId"
                element={<AdminAddEditGroup />}
              />
              <Route
                path="/admin/reference-data/dorms"
                element={<AdminManageDorms />}
              />
              <Route
                path="/admin/reference-data/dorms/add"
                element={<AdminAddEditDorm />}
              />
              <Route
                path="/admin/reference-data/dorms/:dormId"
                element={<AdminAddEditDorm />}
              />
              <Route
                path="/admin/reference-data/classrooms"
                element={<AdminManageClassrooms />}
              />
              <Route
                path="/admin/reference-data/classrooms/add"
                element={<AdminAddEditClassroom />}
              />
              <Route
                path="/admin/reference-data/classrooms/:classroomId"
                element={<AdminAddEditClassroom />}
              />
              <Route
                path="/admin/reference-data/buildings"
                element={<AdminManageBuildings />}
              />
              <Route
                path="/admin/reference-data/buildings/add"
                element={<AdminAddEditBuilding />}
              />
              <Route
                path="/admin/reference-data/buildings/:buildingId"
                element={<AdminAddEditBuilding />}
              />
              <Route
                path="/admin/reference-data/churches"
                element={<AdminManageChurches />}
              />
              <Route
                path="/admin/reference-data/churches/add"
                element={<AdminAddEditChurch />}
              />
              <Route
                path="/admin/reference-data/churches/:churchId"
                element={<AdminAddEditChurch />}
              />
              <Route
                path="/admin/reference-data/events"
                element={<AdminManageEvents />}
              />
              <Route
                path="/admin/reference-data/events/add"
                element={<AdminAddEditEvent />}
              />
              <Route
                path="/admin/reference-data/events/:eventId"
                element={<AdminAddEditEvent />}
              />
              <Route
                path="/admin/reference-data/meals"
                element={<AdminManageMeals />}
              />
              <Route
                path="/admin/reference-data/meals/add"
                element={<AdminAddEditMeal />}
              />
              <Route
                path="/admin/reference-data/meals/:mealId"
                element={<AdminAddEditMeal />}
              />
              <Route
                path="/admin/reference-data/lodging"
                element={<AdminManageLodging />}
              />
              <Route
                path="/admin/reference-data/lodging/add"
                element={<AdminAddEditLodging />}
              />
              <Route
                path="/admin/reference-data/lodging/:lodgingId"
                element={<AdminAddEditLodging />}
              />
              <Route
                path="/admin/reference-data/roles"
                element={<AdminManageRoles />}
              />
              <Route
                path="/admin/reference-data/roles/add"
                element={<AdminAddEditRole />}
              />
              <Route
                path="/admin/reference-data/roles/:roleId"
                element={<AdminAddEditRole />}
              />
              <Route
                path="/admin/reference-data/options"
                element={<AdminManageOptions />}
              />
              <Route path="/admin/statistics/" element={<AdminStatistics />} />
              <Route
                path="/admin/statistics/resident-commuter-status"
                element={<AdminStatisticsResidentCommuter />}
              />
              <Route
                path="/admin/statistics/grades"
                element={<AdminStatisticsGrades />}
              />
              <Route
                path="/admin/statistics/dorms"
                element={<AdminStatisticsDorms />}
              />
            </Route>
            <Route
              element={
                <UserRoutes
                  excludeRolePermissions={["change_user_passwords"]}
                />
              }
            >
              <Route
                path="/registration/change-password"
                element={<ChangePassword />}
              />
            </Route>
            <Route
              element={
                <UserRoutes
                  requiredRolePermissions={["change_user_passwords"]}
                />
              }
            >
              <Route
                path="/admin/change-password/:id"
                element={<ChangePassword />}
              />
            </Route>
          </Routes>
        </Router>
      </UserContextProvider>
    </ChakraProvider>
  );
}

export default App;
