import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../utils/axios";
import { Box, Button, Heading, Input, Stack } from "@chakra-ui/react";
function ChangePassword() {
  const { user, language } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formMessage, setFormMessage] = useState("");

  const [targetUser, setTargetUser] = useState(null);

  useEffect(() => {
    if (user?.role?.permissions?.includes("change_user_passwords")) {
      api.get(`/api/users/${id}`).then((res) => {
        setTargetUser(res.data);
      });
    }
  }, []);

  function prettyPrintUser(user) {
    if (!user) {
      return "";
    }
    let name = `${user.firstName} ${user.lastName}`;
    if (user.chineseName) {
      name += ` (${user.chineseName})`;
    }
    return `for ${name}, ${user.email}`;
  }

  async function submitUpdatePassword(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setFormMessage("Passwords do not match.");
      return;
    }
    if (newPassword.length < 6) {
      setFormMessage("Passwords must be at least 6 characters long.");
      return;
    }

    try {
      const response = user?.role?.permissions?.includes(
        "change_user_passwords"
      )
        ? await api.post(`/api/auth/reset-password-override/${id}`, {
            newPassword,
          })
        : await api.post(`/api/auth/reset-password-tokenless/${user._id}`, {
            currentPassword,
            newPassword,
          });

      if (response.status === 200) {
        setFormMessage(
          language === "en" ? "Password successfully updated." : "密码更改成功!"
        );
      }
    } catch (err) {
      if (err.response.status === 400) {
        setFormMessage(err.response.data.message);
      } else {
        setFormMessage("Unknown server error ", err);
      }
    }
  }

  return (
    <>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Change Password" : "更改密码"}
      </Heading>
      {user?.role?.permissions?.includes("change_user_passwords") && (
        <Heading as="h2" size="lg" textAlign="center">
          {prettyPrintUser(targetUser)}
        </Heading>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin={10}
      >
        <form onSubmit={submitUpdatePassword}>
          <Stack direction="column" spacing={5}>
            {!user?.role?.permissions?.includes("change_user_passwords") && (
              <Input
                onChange={(e) => setCurrentPassword(e.target.value)}
                value={currentPassword}
                type="password"
                placeholder={
                  language === "en" ? "Current Password" : "(Current Password)"
                }
              />
            )}
            <Input
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              type="password"
              placeholder={
                language === "en" ? "New Password" : "(New Password)"
              }
            />
            <Input
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              type="password"
              placeholder={
                language === "en"
                  ? "Confirm New Password"
                  : "(Confirm New Password)"
              }
            />
            <p>{formMessage}</p>
            <Button type="submit" colorScheme="green">
              {" "}
              {language === "en" ? "Update Password" : "更改"}
            </Button>

            <Button
              type="button"
              alignSelf="center"
              margin="0px 20px"
              onClick={() => navigate(-1)}
            >
              {language === "en" ? "Back" : "回上頁"}
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
}

export default ChangePassword;
