import { useContext } from "react";
import { Heading, Text } from "@chakra-ui/react";
import { UserContext } from "../../../context/UserContext";

export function MDRLandingPage() {
  const { language } = useContext(UserContext);

  return (
    <div>
      <Heading as="h4" size="md" textAlign="center">
        {language === "en"
          ? `Welcome to MDR Registration! Please click on the Registration tab above to begin.`
          : `[Welcome to MDR Registration! Please click on the Registration tab above to begin.]`}
      </Heading>
      <Text textAlign="center">TODO: Add MDR retreat info</Text>
    </div>
  );
}
