import React, { useContext, useEffect, useState } from "react";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  Checkbox,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminManageOptions() {
  const { user, language } = useContext(UserContext);

  const [options, setOptions] = useState([]);

  function fetchReferenceData() {
    api.get("/api/options").then((res) => {
      setOptions(res.data);
    });
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  // async function handleDelete(d) {
  //   if (!hasPermission(user, "edit_reference_data_options")) {
  //     // eslint-disable-next-line no-alert
  //     alert("You do not have permissions to delete options.");
  //     return;
  //   }

  //   if (
  //     // eslint-disable-next-line no-alert
  //     window.confirm("Are you sure you wish to delete this option?")
  //   ) {
  //     try {
  //       await api.delete(`/api/options/${d._id}`);
  //       fetchReferenceData();
  //     } catch (err) {
  //       // eslint-disable-next-line no-console
  //       console.log(err);
  //     }

  //     // eslint-disable-next-line no-alert
  //     alert("Option deleted");
  //   }
  // }

  return (
    <VStack spacing={5} marginBottom={10}>
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Reference Data" : "參考資料"}
      </Heading>
      <Heading as="h4" size="md">
        Options
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Option Name</Th>
              <Th>Description</Th>
              <Th>Enabled</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {options &&
              options
                .sort((a, b) => a.val - b.val)
                .map((option) => (
                  <Tr key={option._id}>
                    <Td>{option.name}</Td>
                    <Td>{option.description}</Td>
                    <Td>
                      <Checkbox
                        id={option._id}
                        isChecked={option.enabled}
                        isDisabled={
                          !hasPermission(user, "edit_reference_data_options") ||
                          option.val === 1
                        }
                        onChange={async (e) => {
                          if (
                            // eslint-disable-next-line no-alert
                            window.confirm(
                              "Note: Editing these options is not recommended when registration is open. Could result in users having options selected getting removed."
                            )
                          ) {
                            try {
                              const optionsObj = {
                                ...option,
                                enabled: e.target.checked,
                              };

                              await api.put(
                                `/api/options/${option._id}`,
                                optionsObj
                              );
                              fetchReferenceData();
                            } catch (err) {
                              // eslint-disable-next-line no-console
                              console.log(err);
                            }
                          }
                        }}
                      />
                    </Td>
                    {/* <Td>
                    <Button
                      as={ReachLink}
                      to={`/admin/reference-data/options/${option._id}`}
                      size="md"
                      colorScheme="yellow"
                      isDisabled={!hasPermission(user, "edit_reference_data_options")}
                    >
                      {language === "en" ? "Update" : "[Update]"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={() => handleDelete(option)}
                      isDisabled={!hasPermission(user, "edit_reference_data_options")}
                    >
                      Delete
                    </Button>
                  </Td> */}
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button as={ReachLink} to="/admin/reference-data" margin="0px 20px">
          Back
        </Button>
        {/* <Button
          as={ReachLink}
          to={hasPermission(user, "edit_reference_data_options") ? "/admin/reference-data/options/add" : "#"}
          colorScheme="blue"
          size="md"
          margin="0px 20px"
          isDisabled={!hasPermission(user, "edit_reference_data_options")}
        >
          {language === "en" ? "Add Option" : "[Add Option]"}
        </Button> */}
      </Box>
    </VStack>
  );
}

export default AdminManageOptions;
