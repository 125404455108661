import React, { createContext, useEffect, useMemo, useState } from "react";
import { Spinner } from "@chakra-ui/react";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [language, setLanguage] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    } else {
      setLanguage("zh");
    }

    const user = localStorage.getItem("userData");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const value = useMemo(
    () => ({
      user,
      setUser,
      language,
      toggleLanguage: () => {
        if (language === "en") {
          setLanguage("zh");
          localStorage.setItem("language", "zh");
        } else {
          setLanguage("en");
          localStorage.setItem("language", "en");
        }
      },
    }),
    [user, language]
  );

  if (language === "") {
    return <Spinner />;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
