import React, { useContext, useEffect, useState } from "react";
import api from "../../utils/axios";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { hasPermission, isAdmin, isUser } from "../../utils/helpers";

function RegistrationFamily() {
  const { user, language } = useContext(UserContext);
  const { familyId } = useParams();
  const navigate = useNavigate();

  const [users, setUsers] = useState(null);

  function fetchUserData() {
    api.get(`/api/users/family/${familyId || user.familyId}`).then((res) => {
      setUsers(res.data);
    });
  }

  useEffect(() => {
    fetchUserData();
  }, []);

  function handleAddFamilyMember() {
    navigate("add");
  }

  function handleUpdate(id) {
    if (isAdmin(user)) {
      navigate(`/admin/registration/${id}`);
    } else {
      navigate(`/registration/${id}`);
    }
  }

  async function handleDelete(u) {
    const deletePrompt = u.registerer
      ? language === "en"
        ? `Warning: User is registerer, deleting them will make this family uneditable. Are you sure you wish to delete user ${u.firstName} ${u.lastName}?`
        : `注意: 删除作家長的使用者,不再能够變更其他家庭成员的資料.`
      : language === "en"
      ? `Are you sure you wish to delete user ${u.firstName} ${u.lastName}?`
      : `確認要删除此家庭成員 ${u.firstName} ${u.lastName}?`;

    if (
      // eslint-disable-next-line no-alert
      window.confirm(deletePrompt)
    ) {
      try {
        await api.delete(`/api/users/${u._id}`);
        fetchUserData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }

      // eslint-disable-next-line no-alert
      alert("User deleted");
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading textAlign="center" margin="20px">
        {language === "en" ? "Registration" : "登记注册"}
      </Heading>
      <Heading as="h4" size="md">
        {language === "en"
          ? `Family Id: ${familyId || user.familyId}`
          : `家庭代碼: ${familyId || user.familyId}`}
      </Heading>
      {!(
        (isAdmin(user) &&
          !hasPermission(user, "edit-users") &&
          user.familyId?.toString() !== familyId) ||
        (isUser(user) && !user.registerer)
      ) && (
        <Button onClick={() => handleAddFamilyMember()} margin={3}>
          {language === "en" ? `Add Family Member` : `加入新成員`}
        </Button>
      )}
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th scope="col">{language === "en" ? `First Name` : `名字`}</Th>
              <Th scope="col">{language === "en" ? `Last Name` : `姓氏`}</Th>
              <Th scope="col">
                {language === "en" ? `Chinese Name` : `中文姓名`}
              </Th>
              <Th scope="col">{language === "en" ? `Email` : `電郵`}</Th>
              <Th scope="col">
                {language === "en" ? `Attending` : `參加與否`}
              </Th>
              <Th scope="col">
                {language === "en" ? `Registration Status` : `註册狀態`}
              </Th>
              <Th scope="col"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {users &&
              users.map((u) => (
                <Tr key={u._id}>
                  <Td>{u.firstName}</Td>
                  <Td>{u.lastName}</Td>
                  <Td>{u.chineseName}</Td>
                  <Td>{u.email}</Td>
                  <Td>
                    {u.registrationStatus === "Not Attending" ? "No" : "Yes"}
                  </Td>
                  <Td>{u.registrationStatus}</Td>
                  <Td>
                    <Button
                      colorScheme={user?.registerer ? "yellow" : "gray"}
                      onClick={() => handleUpdate(u._id)}
                    >
                      {user?.registerer || isAdmin(user)
                        ? language === "en"
                          ? `View/Update`
                          : `更改`
                        : language === "en"
                        ? `View`
                        : `覧閲`}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      isDisabled={
                        u._id === user._id ||
                        (isUser(user) && (!user.registerer || u.registerer))
                      }
                      colorScheme="red"
                      onClick={() => handleDelete(u)}
                    >
                      {language === "en" ? `Delete` : `删除`}
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {isAdmin(user) && (
        <Button as={ReachLink} to="/admin/registration" margin="20px 0px">
          {language === "en" ? `Back` : `回上頁`}
        </Button>
      )}
    </Box>
  );
}

export default RegistrationFamily;
