import {
  Select,
  Flex,
  IconButton,
  Text,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";

function AdminUserTableFooter({
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  pageTotal,
  pageNumberChange,
  setPageNumberChange,
}) {
  return (
    <Flex justifyContent="space-between" m={3} alignItems="center">
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            onClick={() => {
              setPageNumber(1);
              setPageNumberChange(!pageNumberChange);
            }}
            isDisabled={pageNumber === 1}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={() => {
              setPageNumber(pageNumber - 1);
              setPageNumberChange(!pageNumberChange);
            }}
            isDisabled={pageNumber === 1}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>
      <Flex alignItems="center">
        <Text flexShrink="0" ml={4} mr={8}>
          Page{" "}
          <Text fontWeight="bold" as="span">
            {pageNumber}
          </Text>{" "}
          of{" "}
          <Text fontWeight="bold" as="span">
            {pageTotal}
          </Text>
        </Text>
        <Text flexShrink="0">Go to page:</Text>{" "}
        <NumberInput
          ml={2}
          mr={8}
          w={28}
          min={1}
          max={pageTotal}
          value={pageNumber}
          onChange={(value) => {
            setPageNumber(value);
            setPageNumberChange(!pageNumberChange);
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Select
          w={32}
          mr={4}
          value={pageSize}
          onChange={(e) => {
            setPageNumber(1);
            setPageSize(Number(e.target.value));
          }}
        >
          {[25, 50, 100, 500, 1000].map((pgSize) => (
            <option key={pgSize} value={pgSize}>
              Show {pgSize}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={() => {
              setPageNumber(pageNumber + 1);
              setPageNumberChange(!pageNumberChange);
            }}
            isDisabled={pageNumber >= pageTotal}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            onClick={() => {
              setPageNumber(pageTotal);
              setPageNumberChange(!pageNumberChange);
            }}
            isDisabled={pageNumber >= pageTotal}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
}

export default AdminUserTableFooter;
