import React, { useContext, useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { Heading } from "@chakra-ui/react";
import { hasPermission, isAdmin, isUser } from "./helpers";

function UserRoutes({
  notLoggedInOnly,
  userOnly,
  adminOnly,
  requiredRolePermissions = [],
  excludeRolePermissions = [],
}) {
  const { user, setUser, language } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);

  const [registrationIsOpen, setRegistrationIsOpen] = useState(false);
  useEffect(() => {
    axios.get("/api/events/upcoming").then((res) => {
      if (res.data.length > 0) {
        setRegistrationIsOpen(res.data[0].isOpen);
      }
      setEventLoading(false);
    });

    const userData = localStorage.getItem("userData");

    if (user) {
      setLoading(false);
      return;
    }

    if (userData) {
      setUser(JSON.parse(userData));
      setLoading(false);
      return;
    }

    axios
      .get("/api/auth/authenticate-user-with-token")
      .then((res) => {
        if (res.status == 200) {
          setUser(res.data);
          localStorage.setItem("userData", JSON.stringify(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (eventLoading || loading) {
    return <></>;
  }

  if (notLoggedInOnly && !user) {
    return <Outlet />;
  } else if (notLoggedInOnly && user) {
    return <Navigate to="/" />;
  }

  if (userOnly && isUser(user)) {
    return <Outlet />;
  }

  if (!userOnly && adminOnly && isAdmin(user)) {
    return <Outlet />;
  }

  if (
    !userOnly &&
    requiredRolePermissions.every((permission) =>
      hasPermission(user, permission)
    ) &&
    !excludeRolePermissions.some((permission) =>
      hasPermission(user, permission)
    )
  ) {
    return <Outlet />;
  }

  return !registrationIsOpen ? (
    <div>
      <Heading textAlign="center" margin="20px">
        Registration Closed
      </Heading>
      <Heading as="h4" size="md" textAlign="center">
        {language === "en"
          ? "Registration is not currently open at this time. Please contact your church if you have any questions."
          : "東岸特會的註冊登记手续尚未開啓. 若您有𨶹於特會的任何問題, 請與您的教會聯絡."}
      </Heading>
    </div>
  ) : (
    <Navigate to="/login" />
  );
}

export default UserRoutes;
