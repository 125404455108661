import React, { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Link as ReachLink,
} from "react-router-dom";
import api from "../../../../utils/axios";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  VStack,
  Select,
} from "@chakra-ui/react";
import { UserContext } from "../../../../context/UserContext";
import { hasPermission } from "../../../../utils/helpers";

function AdminAddEditMeal() {
  const { user, language } = useContext(UserContext);
  const { mealId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [mealName, setMealName] = useState("");
  const [mealDate, setMealDate] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  function isAddPage() {
    return location.pathname.split("/").pop() === "add";
  }

  function fetchReferenceData() {
    if (!isAddPage()) {
      api.get(`/api/meals/${mealId}`).then((res) => {
        setMealName(res.data.name || "");
        setMealDate(new Date(res.data.date) || null);
      });
    }
  }

  useEffect(() => {
    fetchReferenceData();
  }, []);

  async function handleSubmitData(e) {
    e.preventDefault();

    if (!hasPermission(user, "edit_reference_data_meals")) {
      // eslint-disable-next-line no-alert
      alert("You do not have permissions to edit meals.");
      return;
    }

    setErrorName(mealName.length === 0);
    setErrorDate(mealDate == null);

    if (mealName.length === 0 || !mealDate) {
      return;
    }

    const mealObj = {
      name: mealName,
      date: mealDate,
    };

    try {
      if (isAddPage()) {
        await api.post(`/api/meals`, mealObj);
      } else {
        await api.put(`/api/meals/${mealId}`, mealObj);
      }
      navigate("/admin/reference-data/meals");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom={10}
    >
      <Heading as="h4" size="md" margin="20px">
        {isAddPage() ? "Add Meal" : "Edit Meal"}
      </Heading>
      <form onSubmit={handleSubmitData}>
        <VStack spacing={4}>
          <FormControl isInvalid={errorName}>
            <FormLabel>Meal Name:</FormLabel>
            {/* <Input
              placeholder={language === "en" ? "Name" : "(Name)"}
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
            /> */}
            <Select
              placeholder={language === "en" ? "Select Meal" : "[Select Meal]"}
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
            >
              <option value="Breakfast">Breakfast</option>
              <option value="Lunch">Lunch</option>
              <option value="Dinner">Dinner</option>
            </Select>
            <FormErrorMessage>Name is required.</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errorDate}>
            <FormLabel>
              {language === "en" ? "Meal Date" : "(Meal Date)"}
            </FormLabel>
            <Input
              placeholder="Select Meal Date"
              size="md"
              type="date"
              value={mealDate ? mealDate.toISOString().split("T")[0] : ""}
              onChange={(e) => {
                if (Number.isNaN(Date.parse(e.target.value))) {
                  setMealDate(null);
                } else {
                  setMealDate(new Date(e.target.value));
                }
              }}
              isDisabled={!hasPermission(user, "edit_reference_data_meals")}
            />
            <FormErrorMessage>Date is required.</FormErrorMessage>
          </FormControl>
          <Box>
            <Button
              as={ReachLink}
              to="/admin/reference-data/meals"
              margin="0px 20px"
            >
              Back
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!hasPermission(user, "edit_reference_data_meals")}
            >
              {language === "en" ? "Submit" : "(Submit)"}
            </Button>
          </Box>
        </VStack>
      </form>
    </Box>
  );
}

export default AdminAddEditMeal;
