export function range(min, max) {
  var len = max - min + 1;
  var arr = new Array(len);
  for (var i = 0; i < len; i++) {
    arr[i] = min + i;
  }
  return arr;
}

export function isUser(user) {
  return user?.role?.permissions?.length == 0;
}

export function isAdmin(user) {
  return user && !isUser(user);
}

export function hasPermission(user, permissions) {
  if (!user?.role?.permissions) {
    return false;
  }

  if (typeof permissions === "string") {
    permissions = [permissions];
  }

  return permissions.some((permission) =>
    user.role?.permissions?.includes(permission)
  );
}
